<script setup lang="ts">
import { useUserStore } from '~/store/user'

const { t } = useI18n()
const router = useRouter()

const { isLogin } = useUserStore()
const goExchange = () => {
  if (!isLogin)
    return router.push('/register')

  return router.push('/exchange')
}
</script>

<template>
  <div
    pt-14
    pb-11
  >
    <div
      content
      flex
      items-center
    >
      <div shrink-0>
        <img
          mt-6
          h-135
          w-163
          src="~@/assets/img/home/custom.png"
        >
      </div>
      <div ml-25>
        <p
          text="8.5"
          font-bold
        >
          {{ t('让今日,') }}
        </p>
        <p
          text="8.5"
          font-bold
        >
          {{ t('交易您的未来!') }}
        </p>
        <p
          c="$ex-title"
          mt-6
        >
          {{ t('在Bitoy交易平台开启您的首个加密账户') }}
        </p>

        <el-button
          type="primary"
          size="large"
          mt-11
          px="!10"
          py="!6"
          rounded="!full"
          @click="goExchange"
        >
          {{ t('即刻开始使用') }}
        </el-button>
      </div>
    </div>
  </div>
</template>
