<script setup lang="ts">
import Notice from '~/pages/components/notice.vue'

const router = useRouter()
const { t } = useI18n()

const account = ref('')
</script>

<template>
  <div>
    <div flex>
      <el-input
        v-model="account"
        class="no-bg h-12 rounded-4"
        :placeholder="t('邮箱/手机号')"
      />
      <el-button
        type="primary"
        size="large"
        ml-4
        rounded="!full"
        @click="router.push(`/register?account=${account}`)"
      >
        {{ t('立即注册') }}
      </el-button>
    </div>
    <notice mt-14 />
  </div>
</template>

<style lang="scss" scoped>
:deep(.el-button) {
  --el-button-size: 48px;
  padding-left: 30px;
  padding-right: 30px;
}
</style>
