<!-- eslint-disable @typescript-eslint/consistent-type-imports -->
<script setup lang="ts">import { toRef as _toRef, computed as _computed, ref as _ref } from 'vue';

import { useI18n } from 'vue-i18n'
import dayjs from 'dayjs'
import { ElDropdown as ElDropdownComp } from 'element-plus'
import QRCode from 'qrcode'
import BN from 'bignumber.js'
import { ArrowLeft, ArrowRight } from '@element-plus/icons-vue'
import type { Options } from '@popperjs/core'
import type { NoticeTypeReadStatus } from '../types/index'
import ModalUserSetting from './ModalUserSetting.vue'
import ModalLanguageSetting from './ModalLanguageSetting.vue'
import * as Helper from '~/utils/helper'
import { useConfigStore } from '~/store/config'
import type { HeadConfigList } from '~/types/index'
import { useUserStore } from '~/store/user'
import { logout, readAll } from '~/api'
import Cookie from '~/utils/cookie'
import { GET_NOTICE_LIST } from '~/api/URL_CONSTANT'
import { LoginShowStatus, NoticeReadStatus, NoticeType } from '~/types/enums'
import { getNoticeDetail, getNoticeTypeReadStatus } from '~/api/index'
import { toThousand } from '~/utils/utils'
import { useAssetStore } from '~/store/assets'

const { t } = useI18n()
const { push, resolve } = useRouter()
const route = useRoute()

const configStore = useConfigStore()
const { usdtToCurrencyValue } = configStore
const __$temp_1 = (storeToRefs(configStore)),
  indexConfig = _toRef(__$temp_1, 'indexConfig'),
  webConfig = _toRef(__$temp_1, 'webConfig'),
  showAgent = _toRef(__$temp_1, 'showAgent'),
  currentThemeGetter = _toRef(__$temp_1, 'currentThemeGetter'),
  curUnit = _toRef(__$temp_1, 'curUnit'),
  headConfigList = _toRef(__$temp_1, 'headConfigList');

const userStore = useUserStore()
const __$temp_2 = (storeToRefs(userStore)),
  username = _toRef(__$temp_2, 'username'),
  isLogin = _toRef(__$temp_2, 'isLogin'),
  baseInfo = _toRef(__$temp_2, 'baseInfo'),
  userOpenInfo = _toRef(__$temp_2, 'userOpenInfo');

const assetStore = useAssetStore()
const { getAssetInfoAction, toggleIsShow } = assetStore
const { assetInfo, isShow } = storeToRefs(assetStore)

const link: Record<string, string> = {
  otc: location.origin.replace('//www', '//otc'),
  exchange: Helper.formatUrl('/exchange'),
  option: Helper.formatUrl('/option/quote'),
  explore: Helper.formatUrl('/explore/busdt'),
  guild: Helper.formatUrl('/guild'),
  bonus: Helper.formatUrl('/bonus/'),
  futures: Helper.formatUrl('/futures'),
  margin: Helper.formatUrl('/cross-margin'),
  agentSystem: Helper.formatUrl('/agentCenter'),
  rank: Helper.formatUrl('/rank')
}

const links = _computed(() => {
  const arr: HeadConfigList[] = []
  headConfigList.value?.forEach((el) => {
    if (el.link === 'agentSystem' && !showAgent.value)
      return

    if (el.system && link[el.link])
      el.link = link[el.link]
    else if (/^\//.test(el.link))
      el.link = Helper.formatUrl(el.link)

    if (el.children && el.children.length) {
      el.children.forEach((_el) => {
        if (/^\//.test(_el.link))
          _el.link = Helper.formatUrl(_el.link)
      })
    }
    arr.push(el)
  })

  const headFilterTypes = [LoginShowStatus.BOTH, isLogin.value ? LoginShowStatus.LOGIN : LoginShowStatus.UN_LOGIN]

  return arr.filter(item => headFilterTypes.includes(item.loginShow))
})

const orderDropdownItems = _computed(() => {
  return [
    {
      show: webConfig.value?.functions.exchange ?? false,
      label: t('币币订单'),
      command: {
        url: '/order/exchange/order?type=0',
        blank: false,
        useRouter: true
      }
    },
    {
      show: webConfig.value?.functions.otc ?? false,
      label: t('法币订单'),
      command: {
        url: Helper.formatUrl('/otc/order').replace('www', 'otc'),
        blank: false
      }
    },
    {
      show: webConfig.value?.functions.margin ?? false,
      label: t('杠杆订单'),
      command: {
        url: Helper.formatUrl('/margin/order'),
        blank: false
      }
    },
    {
      show: webConfig.value?.functions.futures ?? false,
      label: t('永续合约订单'),
      command: {
        url: '/order/future/order?type=0',
        blank: false,
        useRouter: true
      }
    },
    {
      show: webConfig.value?.functions.bonus ?? false,
      label: t('理财订单'),
      command: {
        url: Helper.formatUrl('/staking/order'),
        blank: false
      }
    },
    {
      show: webConfig.value?.functions.ieo ?? false,
      label: t('申购订单'),
      command: {
        url: '/ieo/order',
        blank: false,
        useRouter: true
      }
    }

  ].filter(item => item.show)
})

const assetsDropdownItems = _computed(() => {
  return [
    {
      label: t('钱包资产'),
      command: {
        url: '/assets/wallet',
        blank: false,
        useRouter: true
      },
      show: webConfig.value?.functions.exchange ?? false
    },
    {
      label: t('杠杆资产'),
      command: {
        url: Helper.formatUrl('/margin/finance'),
        blank: false
      },
      show: webConfig.value?.functions.margin ?? false
    },
    {
      label: t('永续合约资产'),
      command: {
        url: '/assets/contract',
        blank: false,
        useRouter: true
      },
      show: webConfig.value?.functions.futures ?? false
    },
    {
      label: t('币多多资产'),
      command: {
        url: '/assets/finance',
        blank: false,
        useRouter: true
      },
      show: webConfig.value?.functions.bonus ?? false
    },
    {
      label: t('跟单资产'),
      command: {
        url: '/assets/follow',
        blank: false,
        useRouter: true
      },
      show: webConfig.value?.functions.follow ?? false
    },
    {
      label: t('策略资产'),
      command: {
        url: '/assets/strategy',
        blank: false,
        useRouter: true
      },
      show: webConfig.value?.functions.strategy ?? false
    },
    {
      label: t('子账户资产'),
      command: {
        url: Helper.formatUrl('/finance/child'),
        blank: false
      },
      show: webConfig.value?.functions.showSubAcco ?? false
    },
    {
      label: t('活动账户'),
      command: {
        url: Helper.formatUrl('/finance/activtiy'),
        blank: false
      },
      show: baseInfo.value.leader
    }
  ].filter(item => item.show)
})

let announcementsPublishTime = _ref(localStorage.announcementsPublishTime ?? '')

function read() {
  const c = indexConfig.value?.announcements
  if (c?.length) {
    announcementsPublishTime.value = c[0].publishTime
    localStorage.announcementsPublishTime = c[0].publishTime
  }
}
const dot = _computed(() => {
  const c = indexConfig.value?.announcements
  return !!(c?.length && announcementsPublishTime.value !== c[0].publishTime)
})

const options: Partial<Options> = {
  modifiers: [
    {
      name: 'applyArrowHide',
      enabled: true,
      phase: 'write',
      fn({ state }) {
        const { arrow } = state.elements

        if (!arrow)
          return
        arrow.style.display = 'none'
      }
    }
  ]
}
// useRouter表示是本项目内的路由 直接使用router.push
function toLink({
  url,
  blank,
  useRouter
}: {
  url: string
  blank: boolean
  useRouter: boolean
}) {
  if (useRouter) {
    push(url)
    return
  }

  window.open(url, blank ? '_blank' : '_self', 'noopener noreferrer nofollow')
}
const downloadUrlImg = _computed(() => {
  const url = indexConfig.value?.shareConfig?.openUrl

  if (!url)
    return ''
  let downloadUrlDataImg = ''

  // @ts-expect-error has no type
  QRCode.toDataURL(
    url,
    {
      margin: 4,
      width: 150,
      height: 150,
      color: {
        dark: currentThemeGetter.value.mainTitle,
        light: '#FFFFFF00'
      }
    },
    (err: Error, dataUrl: string) => {
      if (err)
        return

      downloadUrlDataImg = dataUrl
    }
  )

  return downloadUrlDataImg
})

async function handleLogout() {
  await logout()
  Cookie.clear('user_id')
  Cookie.clear('account_id')
  delete sessionStorage.userInfo
  location.href = '/'
}
const modalUserSetting
  = useCompRef(ModalUserSetting)
const modalLanguageSetting
  = useCompRef(ModalLanguageSetting)

const __$temp_3 = (
  usePagination(
    GET_NOTICE_LIST,
    { page_size: 3, current: 1 },
    { type: NoticeType.ALL },
    { page_size: 'pageSize' }
  )
),
  list = _toRef(__$temp_3, 'list'),
  getList = _toRef(__$temp_3, 'getList');

let noticeTypeReadStatus = _ref<NoticeTypeReadStatus[]>([])
async function getTypeReadStatus() {
  const { res, err } = await getNoticeTypeReadStatus()

  if (err)
    return

  noticeTypeReadStatus.value = res.data
}

async function getDetail(id: string, messageId: string) {
  await getNoticeDetail({ id, messageId })

  getList.value()
  getTypeReadStatus()
}

async function readAllNotice() {
  await readAll()

  getList.value()
  getTypeReadStatus()
}

watch(
  (isLogin),
  (newVal) => {
    if (!newVal)
      return

    getList.value()
    getTypeReadStatus()
    getAssetInfoAction()
  },
  {
    immediate: true
  }
)
// 10秒钟轮询一次消息
useIntervalFn(() => {
  if (!isLogin.value)
    return

  getList.value()
  getTypeReadStatus()
}, 1000 * 10)

const assetDropdown = useCompRef(ElDropdownComp)
function onAssetDropdownClick(path: string) {
  push(path)
  assetDropdown.value?.handleClose()
}

const menuWrapper = ref<HTMLElement | null>(null)
const flagElement = ref<HTMLElement | null>(null)
const flagIsVisible = ref(false)

const menuWrapperRef = computed(() => {
  if (menuWrapper.value && links.value.length)
    return menuWrapper.value

  return null
})

useIntersectionObserver(
  flagElement,
  ([{ isIntersecting }]) => {
    flagIsVisible.value = isIntersecting
  }
)

const { scrollLeft, scrollRight, arrivedState } = useElementScroll(menuWrapperRef, { behavior: 'smooth', offset: { left: 0, right: 200 } })
</script>

<template>
  <div
    class="broker-header"
    bg="$ex-background"
    b-b="~ solid $ex-splitLine"
    overflow-auto
    font-mo-extra
  >
    <div class="broker-header-content">
      <div
        class="broker-header-left"

        flex items-center
        overflow-x-hidden
      >
        <div
          class="broker-header-logo"
          shrink-0
        >
          <a
            v-if="indexConfig?.logo"
            :href="Helper.formatUrl('/')"
          ><img :src="indexConfig.logo"></a>
        </div>
        <div

          w-5 flex
          shrink-0 items-center
        >
          <el-icon
            v-show="!arrivedState.left"
            cursor-pointer
            @click="() => scrollLeft()"
          >
            <arrow-left />
          </el-icon>
        </div>
        <div
          ref="menuWrapper"
          class="broker-header-menu Body1"
          ml="!0"
          h-full
          overflow-x-hidden
        >
          <div

            h-full w-max
            flex items-center
          >
            <template
              v-for="item in links"
              :key="item.link"
            >
              <el-dropdown
                v-if="item.children?.length"
                size="large"
                :show-arrow="false"
                :popper-options="options"
                popper-class="w-70 !rounded-4 overflow-hidden"
                @command="toLink"
              >
                <a
                  c="$ex-mainTitle"

                  text-4 font-mo-extra
                  hover:color="$primary"
                  :href="item.link"
                  :target="item.blank ? '_blank' : ''"
                  rel="noopener noreferrer nofollow"
                >
                  <div
                    flex
                    items-center
                  >
                    {{ item.text }}
                    <img
                      v-if="item.img"
                      :src="item.img"

                      mx-1 h-4
                      w-4
                    >
                    <i

                      i-iwwa:arrow-down inline-block
                      text-2 leading-6
                    />
                    <span
                      v-if="item.color && item.tag"
                      class="tag"
                      :style="{ background: item.color }"
                    >{{ item.tag }}<em :style="{ borderLeftColor: item.color }" /></span>
                  </div>
                </a>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item
                      v-for="_item in item.children"
                      :key="_item.link"
                      :command="{ url: _item.link, blank: _item.blank }"
                      class="items-center font-mo-medium !flex-wrap !whitespace-normal !py-4"
                    >
                      <div
                        w="8.5"
                        h="8.5"
                        shrink-0
                      >
                        <img

                          h-full w-full
                          :src="_item.img"
                        >
                      </div>
                      <div
                        ml-2
                        flex-1
                      >
                        <p
                          c="$ex-mainTitle"
                          text-sm
                        >
                          {{ _item.text }}
                        </p>
                        <p
                          c="$ex-title"

                          mt-2 text-xs
                        >
                          {{ _item.desc }}
                        </p>
                      </div>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
              <a
                v-else
                c="$ex-mainTitle"
                text-base
                font-mo-extra
                hover:color="$primary"
                :href="item.link"
                :target="item.blank ? '_blank' : ''"
                rel="noopener noreferrer nofollow"
              >
                <div
                  flex
                  items-center
                >
                  {{ item.text }}
                  <img
                    v-if="item.img"
                    :src="item.img"

                    mx-1 h-4
                    w-4
                  >
                  <span
                    v-if="item.color && item.tag"
                    class="tag"
                    :style="{ background: item.color }"
                  >{{ item.tag }}<em :style="{ borderLeftColor: item.color }" />
                  </span>
                </div>
              </a>
            </template>
            <span
              ref="flagElement"
              ml-auto
            />
          </div>
        </div>
        <div

          mx-2 w-5
          flex shrink-0
          items-center
        >
          <el-icon
            v-if="!flagIsVisible"
            cursor-pointer
            @click="() => scrollRight()"
          >
            <arrow-right />
          </el-icon>
        </div>
      </div>
      <div class="Body1 broker-header-right shrink-0 font-mo-extra">
        <div
          v-if="!username"
          key="nologin"
          class="broker-header-sub broker-header-nologin"

          mr-4 flex
          items-center
        >
          <a
            c="$ex-mainTitle"
            hover:color="$primary"
            mr-4
            :href="Helper.formatLoginUrl('/login')"
          >{{ t('登录') }}</a>
          <el-button
            type="primary"
            class="!rounded-full"
            @click="push(Helper.formatLoginUrl('/register'))"
          >
            {{ t('注册') }}
          </el-button>

          <div

            ml-4 inline-block
            w-1px pt-2
            h="3.5"
            bg="$ex-mainTitle"
          />
        </div>
        <div
          v-else
          class="broker-header-sub"
          flex
          items-center
        >
          <el-dropdown
            ref="assetDropdown"
            placement="bottom-end"
            :popper-options="options"
            popper-class="min-w-40 header-popper"
            @command="toLink"
            @visible-change="(visible: boolean) => (visible && getAssetInfoAction())"
          >
            <a
              hover:color="$primary"
              c="$ex-mainTitle"

              mr-4 text-base
              :href="resolve('/assets').href"
            >{{ t('资产') }}
              <i

                i-ri-arrow-down-s-fill inline-block
                mb="-1"
              />
            </a>
            <template #dropdown>
              <div

                bg="$ex-inputBg"

                mt-5 w-56
                rounded-md
              >
                <div p-4>
                  <div
                    c="$ex-title"
                    flex
                    items-center
                    font-400
                  >
                    <span
                      mr-1
                      text-xs
                    >
                      {{ t('总资产折合') }}
                    </span>
                    <i
                      v-if="isShow"
                      cursor-pointer
                      class="iconfont icon-zichan-xianshi"
                      text-sm
                      @click="toggleIsShow"
                    />
                    <i
                      v-if="!isShow"
                      cursor-pointer
                      class="iconfont icon-zichan-yincang"
                      text-sm
                      @click="toggleIsShow"
                    />

                    <el-icon
                      ml-auto
                      cursor-pointer
                      @click="onAssetDropdownClick('/assets')"
                    >
                      <arrow-right />
                    </el-icon>
                  </div>
                  <div
                    mt-6px
                    c="$ex-mainTitle"
                  >
                    <span text-xl>
                      {{
                        isShow
                          ? toThousand(BN(assetInfo?.totalAsset ?? 0).toFixed(2, 1))
                          : '***'
                      }}
                    </span>
                    <span

                      ml-2 text-sm
                    >
                      {{ assetInfo?.unit }}
                    </span>
                  </div>
                  <div
                    c="$ex-title"

                    mt-4px text-xs
                  >
                    ≈{{ curUnit?.prefix
                    }}{{
                      isShow
                        ? toThousand(
                          BN(usdtToCurrencyValue(assetInfo?.totalAsset ?? 0)).toFixed(2, 1),
                        )
                        : '***'
                    }}
                  </div>
                </div>
              </div>
              <div
                flex
                pb-3
                pt-5
              >
                <skew-button
                  :color="currentThemeGetter.primary"
                  position="right"
                  class="mr-2"
                  @click="onAssetDropdownClick('/assets/recharge/USDT')"
                >
                  <span

                    pl-2 text-sm
                    c-white
                  >
                    {{ t('充值') }}
                  </span>
                </skew-button>
                <skew-button
                  :color="currentThemeGetter.inputBg"
                  class="group"
                  @click="onAssetDropdownClick('/assets/withdraw/USDT')"
                >
                  <span

                    c="$ex-mainTitle"
                    pr-2 text-sm
                  >
                    {{ t('提币') }}
                  </span>
                </skew-button>
              </div>
              <el-dropdown-menu>
                <el-dropdown-item
                  v-for="item, idx in assetsDropdownItems"
                  :key="idx"
                  :command="item.command"
                >
                  <span
                    inline-block
                    py="0.5"
                  >
                    {{ item.label }}
                  </span>
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
          <el-dropdown
            placement="bottom-end"
            popper-class="min-w-40 header-popper"
            :popper-options="options"
            @command="toLink"
          >
            <a
              hover:color="$primary"
              c="$ex-mainTitle"

              mr-4 text-base
              @click.prevent="push('/exchange/order')"
            >{{ t('订单')
             }}
              <i

                i-ri-arrow-down-s-fill inline-block
                mb="-1"
              />
            </a>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item
                  v-for="item, idx in orderDropdownItems"
                  :key="idx"
                  :command="item.command"
                >
                  <span
                    inline-block
                    py="0.5"
                  >{{ item.label }}
                  </span>
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
          <el-dropdown
            popper-class="w-50 header-popper"
            :popper-options="options"
            @command="toLink"
          >
            <a
              hover:color="$primary"
              c="$ex-mainTitle"
              text-base
              :href="resolve('/user').href"
            >
              <i
                class="iconfont icon-daohang-gerenzhongxin"
                inline-block
                mb="-1"
                text="5.5"
              />
            </a>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item disabled>
                  <div
                    class="broker-header-sub-user !pl-0"
                    truncate
                  >
                    <img
                      v-if="userOpenInfo.photo"
                      :src="userOpenInfo.photo"
                    >
                    <img
                      v-else
                      class="default-icon"
                      src="@/assets/img/face.png"
                    >
                    <strong
                      c="$ex-mainTitle"
                      truncate
                      text-14px
                    >{{
                      userOpenInfo.nickName || username
                    }}</strong>
                  </div>
                </el-dropdown-item>
                <el-dropdown-item
                  :command="{
                    url: '/user',
                    useRouter: true,
                    blank: false,
                  }"
                >
                  <span>{{ t('用户中心') }}</span>
                </el-dropdown-item>
                <el-dropdown-item
                  :command="{
                    url: '/welfare/experience',
                    blank: false,
                    useRouter: true,
                  }"
                >
                  <span>{{ t('我的卡券') }}</span>
                </el-dropdown-item>
                <el-dropdown-item
                  v-if="webConfig?.functions.userLevel"
                  :command="{
                    url: Helper.formatUrl('/user/grade'),
                    blank: false,
                  }"
                >
                  <span>{{ t('我的等级') }}</span>
                </el-dropdown-item>
                <el-dropdown-item
                  v-if="baseInfo.leader"
                  :command="{
                    url: Helper.formatUrl('/captain/index'),
                    blank: false,
                  }"
                >
                  <span :href="Helper.formatUrl('/captain/index')">{{
                    t('霍比特队长')
                  }}</span>
                </el-dropdown-item>
                <el-dropdown-item
                  v-else-if="webConfig?.functions.invite"
                  :command="{
                    url: '/invite',
                    useRouter: true,
                    blank: false,
                  }"
                >
                  <span>{{ t('我的邀请') }}</span>
                </el-dropdown-item>
                <el-dropdown-item
                  v-if="baseInfo.isAgent"
                  :command="{ url: Helper.formatUrl('/broker'), blank: false }"
                >
                  <span>{{ t('经纪人管理') }}</span>
                </el-dropdown-item>
                <el-dropdown-item
                  border="t-1 $ex-splitLine"
                  disabled
                >
                  <div

                    h-full w-full
                    cursor-pointer py-2
                  >
                    <a

                      h-full w-full
                      c="$ex-title"
                      @click="handleLogout"
                    >{{ t('退出') }}</a>
                  </div>
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
          <div

            mx-4 mt-1
            inline-block w-1px
            h="3.5"
            bg="$ex-mainTitle"
          />
        </div>
        <div
          v-if="isLogin && list.length"
          class="broker-header-note Body2"
          mr-4
        >
          <el-dropdown
            placement="bottom"
            popper-class="w-120"
            :popper-options="options"
          >
            <span cursor-pointer>
              <el-badge
                mb="-2"
                :is-dot="noticeTypeReadStatus.some(
                  item => item.readStatus === NoticeReadStatus.UNREAD,
                )
                "
              >
                <i
                  inline-block
                  class="iconfont icon-daohang-xiaoxi"
                  c="$ex-mainTitle"
                  hover:color="$primary"
                  text="5.5"
                />

                <!-- <Icon type="announcement" size="24" /> -->
              </el-badge>
            </span>
            <template #dropdown>
              <el-dropdown-menu p="!4">
                <el-dropdown-item

                  disabled font-bold
                >
                  <div
                    c="$ex-mainTitle"

                    w-full flex
                    items-center justify-between
                    pb-2 text-base
                  >
                    <p>
                      {{ t('消息通知') }}
                      <span

                        py="0.5"

                        c="$primary"
                        bg="$ex-inputBg"

                        ml-4 cursor-pointer
                        rounded px-1
                        text-sm
                        @click="readAllNotice"
                      >
                        {{ t('全部已读') }}
                      </span>
                    </p>
                    <div

                      cursor-pointer text-center
                      text-sm
                      c="$ex-title"
                      @click="push('/noticeCenter')"
                    >
                      {{ t('查看更多') }} >
                    </div>
                  </div>
                </el-dropdown-item>

                <el-dropdown-item
                  v-for="notice in list"
                  :key="notice.id"
                  overflow-hidden
                >
                  <div
                    w-full
                    @click.stop="() => {
                      notice.readStatus === NoticeReadStatus.UNREAD
                        && getDetail(notice.id, notice.messageId)
                    }
                    "
                  >
                    <h4

                      relative mb-1
                      font-bold
                    >
                      <span
                        v-if="notice.readStatus === NoticeReadStatus.UNREAD"
                        w="1.5"
                        h="1.5"

                        absolute mr-1
                        rounded-full bg-hex-e4464f
                        left="-2"
                        top="1/2"
                        translate-y="-1/2"
                      />

                      {{ notice.title }}
                    </h4>
                    <p
                      truncate
                      text-sm
                    >
                      {{ notice.content }}
                    </p>
                    <p

                      c="$ex-title"
                      mt="2.5"

                      flex justify-between
                      text-xs
                    >
                      <span>
                        {{
                          dayjs(+notice.createAt).format('YYYY-MM-DD HH:mm:ss')
                        }}
                      </span>
                    </p>
                  </div>
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
        <div
          v-if="indexConfig?.announcements.length"
          class="broker-header-note Body2"
          mr-4
        >
          <el-dropdown
            placement="bottom"
            popper-class="w-100"
            :popper-options="options"
            @command="toLink"
          >
            <span
              cursor-pointer
              @click="read"
            >
              <el-badge
                :is-dot="dot"
                mb="-2"
              >
                <i
                  inline-block
                  class="iconfont icon-daohang-ling"
                  c="$ex-mainTitle"
                  hover:color="$primary"
                  text="5"
                />
                <!-- <Icon type="announcement" size="24" /> -->
              </el-badge>
            </span>
            <template #dropdown>
              <el-dropdown-menu p="!4">
                <el-dropdown-item
                  border="b $ex-splitLine"

                  disabled font-bold
                >
                  <div
                    c="$ex-mainTitle"
                    text-base
                  >
                    {{ t('公告中心') }}
                  </div>
                </el-dropdown-item>

                <el-dropdown-item
                  v-for="item in indexConfig?.announcements"
                  :key="item.directUrl"
                  :command="{ url: item.directUrl, blank: true }"
                >
                  <div

                    w-full truncate
                    pr-2
                  >
                    {{ item.title }}
                    <p
                      c="$ex-subTitle"
                      text-sm
                    >
                      {{ dayjs(+item.publishTime).format('YYYY-MM-DD') }}
                    </p>
                  </div>
                </el-dropdown-item>

                <el-dropdown-item
                  v-if="indexConfig?.announcementMoreUrl"
                  :command="{
                    url: indexConfig.announcementMoreUrl,
                    blank: true,
                  }"
                  text-center
                >
                  <div

                    w-full text-center
                  >
                    {{ t('查看更多') }} >
                  </div>
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
        <div
          v-if="indexConfig?.shareConfig?.openUrl"
          class="broker-header-download"
        >
          <el-popover
            placement="bottom-end"
            trigger="hover"
            word-wrap="word-wrap"
            :width="150"
            :show-arrow="false"
            popper-class="!p-0"
          >
            <template #reference>
              <div text-center>
                <a
                  c="$ex-mainTitle"

                  :href="indexConfig.shareConfig.openUrl"
                  target="_blank"
                  mr-4 text-center
                  rel="noopener noreferrer nofollow"
                >
                  <i
                    inline-block
                    class="iconfont icon-daohang-xiazai"
                    text="5.5"
                  />
                </a>
              </div>
            </template>
            <div>
              <img :src="downloadUrlImg">
              <div

                pb-2 text-center
                font-bold
              >
                {{ t('扫码下载APP') }}
              </div>
            </div>
          </el-popover>
        </div>

        <div c="$ex-mainTitle">
          <i
            inline-block
            class="iconfont icon-daohang-yuyan cursor-pointer"
            text="5.5"
            @click="modalLanguageSetting?.toggleVisible(true)"
          />
          <modal-language-setting ref="modalLanguageSetting" />
        </div>

        <div
          v-if="isLogin && ['futures-symbolId', 'exchange-baseToken-quoteToken'].includes(route.name as string)"
          ml-4
          cursor-pointer
          font-mo-medium
        >
          <i
            inline-block
            class="iconfont icon-daohang-shezhi"
            c="$ex-mainTitle"
            text="6"
            mt="-0.5"
            @click="modalUserSetting?.toggleDialogVisible(true)"
          />
          <modal-user-setting ref="modalUserSetting" />
        </div>
        <div

          ml-3 mr-6
          h-7 w-7
        >
          <DarkToggle />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.broker-header {
  min-width: 1280px;

  &-content {
    display: flex;
    height: 56px;
    justify-content: space-between;
  }

  &-left {
    display: flex;
    align-items: center;
  }

  &-logo {
    padding-left: 16px;
    padding-right: 16px;

    img {
      height: 26px;
      display: block;
    }
  }

  &-menu {
    margin-left: 22px;
    white-space: nowrap;

    a {
      display: inline-block;
      line-height: 32px;
      padding: 0 10px;
      position: relative;

      & .tag {
        font-size: 12px;
        height: 16px;
        line-height: 16px;
        border-radius: 4px;
        position: absolute;
        padding: 0 7px;
        right: 0;
        top: -9px;
        transform: scale(0.8);
        color: #fff;
        font-weight: 400;

        em {
          left: 0;
          width: 0;
          border: 4px solid transparent;
          height: 0;
          bottom: -3px;
          position: absolute;
        }
      }
    }

    .ivu-select-dropdown {
      padding: 0;
      box-shadow: none;

      .ivu-dropdown-item {
        padding: 0;
      }

      a {
        display: block;
        padding: 7px 16px;
        margin: 0;
      }
    }
  }

  &-right {
    display: flex;
    align-items: center;
    // padding-right: 6px;
    white-space: nowrap;

    // a {
    //   padding: 0 10px;
    // }

    .ivu-select-dropdown {
      padding: 0;
      box-shadow: none;

      a {
        display: block;
        padding: 7px 10px;
        margin: 0;
        min-width: 120px;
      }
    }
  }

  &-select {
    display: flex;
    align-items: center;

    img {
      width: 20px;
      display: block;
    }
  }

  &-sub {

    &-user {
      padding: 4px 16px 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      img {
        width: 40px;
        height: 40px;
        border-radius: 20px;
        margin-right: 8px;
      }
    }
  }

  &-download {
    &-text {
      text-align: center;
      padding-top: 14px;
    }

    .ivu-poptip-body {
      padding: 0;
    }

    .ivu-poptip-inner {
      border-radius: 0;
    }

    img {
      display: block;
      height: 150px;
    }
  }

  &-note {
    padding: 16px 0;

    &-more {
      text-align: center;
      border-top: 1px solid #f4f4f5;
      line-height: 42px;
    }

    .ivu-badge-dot {
      height: 6px;
      width: 6px;
    }

    &-title {
      padding: 3px 16px 10px;
      border-bottom-width: 1px;
      border-bottom-style: solid;

      i {
        margin-right: 8px;
        margin-top: -2px;
      }
    }

    .ivu-dropdown-menu {
      padding-bottom: 0;
    }

    .ivu-select-dropdown {
      padding: 0;
      box-shadow: none;

      a {
        display: block;
        margin: 0;
        padding-left: 16px;
        padding-right: 16px;
        overflow: hidden;
        min-width: 240px;
        max-width: 380px;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
}
</style>

<style lang="scss">
.default-icon {
  // background: var(--el-color-primary);
  border-radius: 50%;
}
</style>
