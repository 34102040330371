<script setup lang="ts">import { computed as _computed } from 'vue';

import gsap from 'gsap'
import type { NewIndexConfig } from '~/types'
import type { Nullable } from '~/types/utils'

const { t } = useI18n()
withDefaults(defineProps<{
  config: Nullable<NewIndexConfig.ConfigItem>
}>(), { config: null, })
const content = _computed(() => {
  return __props.config?.contentList?.[0]?.content
})

onMounted(() => {
  gsap.from('.help-item', {
    y: 150,
    duration: 0.5,
    opacity: 0,
    stagger: 0.15,
    scrollTrigger: {
      trigger: '.home-help',
      start: 'center 70%'
    }
  })
})

</script>

<template>
  <div
    pt-14
    pb-11
    class="home-help"
  >
    <div content>
      <div
        flex
        justify="center"
        items-center
        flex-col
      >
        <h3
          text-9
          font-bold
        >
          {{ t('更好的未来，从Bitoy交易开始') }}
        </h3>
        <p
          text-lg
          mt-2
          c="$ex-title"
        >
          {{ t('我们是最专业的加密资产交易平台') }}
        </p>
      </div>
      <ul
        mt-12
        flex
      >
        <li
          v-for="item in content?.list"
          :key="item.title"
          flex-1
          mr-5
          class="help-item"
        >
          <a
            :href="item?.link"
            target="_blank"
            rel="noopener noreferrer"
          >
            <el-card
              shadow="never"
              class="!b-0"
            >
              <div
                flex
                flex-col
                items-center
              >
                <img
                  v-if="item.image"
                  :src="item.image"
                  mr-4
                  w-72
                  h-68
                  relative
                  z-2
                  alt=""
                >
                <div
                  font-bold
                  text-2xl
                  pt-20
                  pb-16
                  px-4
                  text-center
                  w-91
                  class="border-box"
                >
                  <div
                    relative
                    z-2
                  >
                    <p>
                      {{ item.title }}
                    </p>
                    <p
                      text-base
                      mt-6
                      c="$ex-title"
                      font="!mo-regular"
                    >
                      {{ item.subTitle }}
                    </p>
                  </div>
                </div>
              </div>
            </el-card>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.help-item{
  .border-box {

    position: relative;
    margin-top: -80px;
    &::after{
      transition: .3s ease;
      opacity: 0;
      transform: translateY(30px);
      border: 2px solid transparent;
      border-radius: 40px;
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-clip: padding-box, border-box;
      background-origin: padding-box, border-box;
      background-image: linear-gradient(to right, var(--ex-background), var(--ex-background)), linear-gradient(347deg, #FD8262, #7E6AFB);
    }

  }

  &:hover {
    .border-box::after {
      opacity: 1;
      transform: translateY(0);
    }
  }
}
</style>
