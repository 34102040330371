import { ElMessage } from 'element-plus'
import { formatUrl } from './helper'

export function copyText(str: string, copyFn: (str: string) => any) {
  // 动态创建 textarea 标签
  const textarea = document.createElement('textarea')
  // 将该 textarea 设为 readonly 防止 iOS 下自动唤起键盘，同时将 textarea 移出可视区域
  textarea.readOnly = true
  textarea.style.position = 'absolute'
  textarea.style.left = '-9999px'
  // 将要 copy 的值赋给 textarea 标签的 value 属性
  textarea.value = str
  // 将 textarea 插入到 body 中
  document.body.appendChild(textarea)
  // 选中值并复制
  textarea.select()
  textarea.setSelectionRange(0, textarea.value.length)
  const result = document.execCommand('Copy')
  if (result)
    ElMessage.success(copyFn('复制成功'))

  document.body.removeChild(textarea)
}

// 全屏
export function launchFullscreen(element: HTMLElement) {
  if (element.requestFullscreen)
    element.requestFullscreen()
}
// 退出全屏
export function exitFullscreen() {
  if (document.exitFullscreen)
    document.exitFullscreen()
}

export function setCSSVariable(propertyName: string, value: string) {
  document.documentElement.style.setProperty(propertyName, value)
}

export function getCSSVariable(propertyName: string) {
  return document.documentElement.style.getPropertyValue(propertyName)
}
// 去主域名下的对应路由
export const toMainHostURL = (url: string) => {
  location.href = formatUrl(url)
}

export function isMobile() {
  return /Android|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)
}

export function downloadFile(url: string, name: string) {
  const a = document.createElement('a')
  a.download = name
  a.href = url
  a.click()
}

export function openNewWindow(href: string) {
  window.open(href, '_blank')
}

export const isSafari = (/Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent))
