<script setup lang="ts">import { toRef as _toRef } from 'vue';

import zhTw from 'element-plus/es/locale/lang/zh-tw'
import en from 'element-plus/es/locale/lang/en'
import ko from 'element-plus/es/locale/lang/ko'
import jp from 'element-plus/es/locale/lang/ja'
import zh from 'element-plus/es/locale/lang/zh-cn'
import tr from 'element-plus/es/locale/lang/tr'
import ru from 'element-plus/es/locale/lang/ru'
import vi from 'element-plus/es/locale/lang/vi'
import th from 'element-plus/es/locale/lang/th'

import {
  colorMix,
  genElementColor,
  genThemeColor
} from './libs/genElementPlusTheme'
import type { QuoteWS } from './types/ws'
import { usePriceAlarm } from './composables/usePriceAlarm'
import { GET_NOTICE_LIST } from './api/URL_CONSTANT'
import { NoticeReadStatus, NoticeType } from './types/enums'
import { getNoticeDetail } from './api'
import Cookie from '~/utils/cookie'
import { WSTYPE } from '~/composables/useWS'
import { useUserStore } from '~/store/user'
import { useConfigStore } from '~/store/config'
import { useAssetStore } from '~/store/assets'
import { getFormatSubMessage } from '~/utils/ws'
import { useFutureStore } from '~/store/future'
import { setCSSVariable } from '~/utils/browserUtils'
import { useExchangeStore } from '~/store/exchange'

const elLangMap: Record<string, any> = {
  'zh-hk': zhTw,
  'zh-cn': zh,
  'en-us': en,
  'ko-kr': ko,
  'ja-jp': jp,
  'th-th': th,
  'tr-tr': tr,
  'ru-ru': ru,
  'vi-vn': vi
}

const locale = elLangMap[localStorage.lang]

const { t } = useI18n()

const configStore = useConfigStore()
const {
  getIndexConfig,
  setWebConfig,
  mergeTheme,
  getShowAgentStatus,
  getRate,
  getCustomKV,
  getInviteCommonConfig,
  getMailSuffixList,
  getHeadConfig,
  getUnits,
  getHotSymbolConfig
  // toggleDarkMode
} = configStore

const {
  currentThemeGetter
  // useDarkMode
} = storeToRefs(configStore)

const assetStore = useAssetStore()
const { getUserAssets } = assetStore

const userStore = useUserStore()
const { setIsLogin, getBaseInfo, getUserSetting, getUserOpenInfoAction }
  = userStore

const router = useRouter()

const { check } = useIPCheck()

if (+import.meta.env.VITE_CHECK_IP) {
  check().then((isChina) => {
    isChina && router.push('/denied')
  })
}

setWebConfig()
getIndexConfig()
getHeadConfig()
getCustomKV()
mergeTheme()
getHotSymbolConfig()
getUnits().then(getRate)
getInviteCommonConfig()
// 获取邮箱前缀
getMailSuffixList()

watch(
  currentThemeGetter,

  (theme) => {
    setCSSVariable('--primary', theme.primary)
    setCSSVariable('--el-color-primary', theme.primary)
    Object.entries(genThemeColor(theme.primary)).forEach(([key, value]) => {
      setCSSVariable(key, value)
    })

    genElementColor('--el-color-danger', theme.error)
    genElementColor('--el-color-error', theme.error)
    genElementColor('--el-color-success', theme.success)

    setCSSVariable('--el-bg-color', theme.background)
    setCSSVariable('--el-color-primary', theme.primary)
    setCSSVariable('--el-disabled-bg-color', theme.disabledBg)
    setCSSVariable('--el-input-text-color', theme.mainTitle)
    setCSSVariable('--el-input-placeholder-color', theme.subTitle)
    setCSSVariable('--el-dialog-bg-color', theme.background)
    setCSSVariable('--el-bg-color-overlay', theme.background)
    setCSSVariable('--el-text-color-regular', theme.subTitle)
    setCSSVariable('--el-text-color-regular', theme.mainTitle)
    setCSSVariable('--el-input-bg-color', theme.background)

    Object.entries(theme).forEach(([key, value]) => {
      setCSSVariable(`--ex-${key}`, value)
      for (let i = 1; i < 10; i += 1) {
        setCSSVariable(
          `--ex-${key}-light-${10 - i}`,
          colorMix('#ffffff', value, i * 0.1)
        )
        setCSSVariable(
          `--ex-${key}-dark-${10 - i}`,
          colorMix('#000000', value, i * 0.1)
        )
      }

      // document.body.style.setProperty(key, value)
    })
  },
  { immediate: true }
)

const accountId = Cookie.get('account_id')
const priceAlarm = usePriceAlarm()
const __$temp_1 = (
  usePagination(
    GET_NOTICE_LIST,
    { page_size: 10, current: 1 },
    { type: NoticeType.PERSONAL },
    { page_size: 'pageSize' }
  )
),
  getNoticeList = _toRef(__$temp_1, 'getList');

if (accountId) {
  getShowAgentStatus()
  getBaseInfo()
  getUserOpenInfoAction()
  getUserSetting().then(mergeTheme)
  priceAlarm.sub()
  setIsLogin(true)
}

const LOOP_DURATION = 1000 * 10

useIntervalFn(
  () => {
    getRate()
    if (accountId) {
      getUserAssets()
      getNoticeList.value()
        .then((res) => {
          const popupList = res?.list?.filter((item) => {
            const search = new URLSearchParams(item.pushUrl)

            return search.get('showPop') && item.readStatus === NoticeReadStatus.UNREAD
          })

          if (!popupList?.length)
            return

          const item = popupList[0]
          const search = new URLSearchParams(item.pushUrl)
          const id = search.get('activityId')

          if (!id)
            return

          ElNotification({
            title: item.title,
            dangerouslyUseHTMLString: true,
            message: `<span class="c-$ex-title">${item.content}</span> <a class="c-$ex-primary" href="/rechargebonus?id=${id}">${t('点击领取')}</a>`
          })

          popupList.forEach((item) => {
            getNoticeDetail({ id: item.id, messageId: item.messageId })
          })
        })
    }
  },
  LOOP_DURATION,
  { immediateCallback: true }
)

const ws = useWS(WSTYPE.QWS)
const brokerMessage = getFormatSubMessage('slowBroker', {
  orgId: configStore.webConfig!.orgId
})

const futureStore = useFutureStore()
const { brokerDataHandler, brokerHttpAction, setTickerInfo } = futureStore

const exchangeStore = useExchangeStore()
const {
  brokerDataHandler: exchangeBrokerDatahandler,
  setTickerInfo: setExchangeTickerInfo
} = exchangeStore

ws.sub([
  {
    payload: brokerMessage,
    httpAction() {
      brokerHttpAction((data) => {
        setTickerInfo(data)
        setExchangeTickerInfo(data)
      })
    },
    callback(data: QuoteWS.BrokerData) {
      brokerDataHandler(data)
      exchangeBrokerDatahandler(data)
    }
  }
])
/** TODO: 查找内存泄漏原因 查找出来之前暂时8小时刷新一次 防止用户崩溃 */
const REFRESH_INTERVAL = 8 * 60 * 60 * 1000

useIntervalFn(() => {
  location.reload()
}, REFRESH_INTERVAL)
</script>

<template>
  <el-config-provider :locale="locale">
    <main>
      <Header v-if="$route.meta.showHeader" />
      <div relative min-h-screen>
        <GlobalAlert />
        <Suspense>
          <!-- component with nested async dependencies -->
          <router-view min-h-screen />
          <!-- loading state via #fallback slot -->
          <template #fallback>
            <div v-loading="true" min-h-screen />
          </template>
        </Suspense>
      </div>
      <Footer v-if="$route.meta.showFooter" />
    </main>
    <!-- <div class="global-ad-wrapper">
      <GlobalAdDialog style="z-index: 9999 !important" />
    </div> -->
  </el-config-provider>
</template>

<style lang="scss" scoped>
* {
  color: var(--ex-mainTitle);
}

.global-ad-wrapper {
  :deep(.el-overlay) {
    z-index: 9998 !important;
  }
}
</style>
