import routes from 'virtual:generated-pages'
import type { RouteRecordName } from 'vue-router'
import { createRouter, createWebHistory } from 'vue-router'
import Cookie from './utils/cookie'
import { useConfigStore } from '@/store/config'

const notNeedLoginRoute: RouteRecordName[] = [
  'history',
  'history-index-rate',
  'history-index-mark',
  'history-index-idx',
  'history-index-idx-symbolId',
  'history-index-mark-symbolId',
  'history-index-rate-symbolId',
  'index',
  'rank-index',
  'rank-homePage-id',
  'contractHegemony',
  'followContract',
  'followContract-followSquare',
  'takeUserInfo-userId',
  'swap',
  'award',
  'futures-symbolId',
  'futures',
  'bot-symbolId',
  'bot',
  'login',
  'register',
  'register-inviteCode',
  'forgetPass',
  'exchange',
  'exchange-baseToken-quoteToken',
  'worldCup',
  'worldCup-guess',
  'worldCup-contract',
  'market',
  'followRecommend',
  'officialChannel',
  'turnTable',
  'teamProfile',
  'ieo',
  'ieo-item-projectId',
  'inviteRegister',
  'otc',
  'earn',
  'guide',
  'guide-search',
  'guide-category',
  'guide-category-categoryId',
  'guide-detail-articleId',
  'traderApply',
  'interface',
  'teamInvite',
  'reward',
  'activities-christmas',
  'applyToken',
  'rechargeBonus',
  'denied'
]
const notNeedFooterRoute: RouteRecordName[] = [
  'futures-symbolId',
  'futures',
  'bot-symbolId',
  'strategy',
  'exchange',
  'exchange-baseToken-quoteToken',
  'register',
  'login',
  'forgetPass',
  'traderApply',
  'teamInvite',
  'activity-christmas',
  'applyToken',
  'rechargeBonus',
  'denied'
]
const notNeedHeaderRoute: RouteRecordName[] = [
  'denied'
]

const guardRoutes = routes.map((route) => {
  route.meta = route.meta ?? {}
  route.meta.needLogin = !notNeedLoginRoute.includes(route.name!)
  route.meta.showFooter = !notNeedFooterRoute.includes(route.name!)
  route.meta.showHeader = !notNeedHeaderRoute.includes(route.name!)

  return route
})
// console.log("🚀 ~ file: routeGuard.ts:73 ~ guardRoutes ~ guardRoutes:", guardRoutes)

const router = createRouter({
  history: createWebHistory(import.meta.env.VITE_ROUTER_BASE_URL),
  routes: guardRoutes,
  scrollBehavior() {
    return { top: 0 }
  }
})

/*
  后期如果有用户反馈更新后需要手动刷新 放开对onError的拦截 目前注释掉 防止用户因网络原因造成重复的刷新
 */
// router.onError((error, to) => {
//   if (
//     error.message.includes('Failed to fetch dynamically imported module')
//     || error.message.includes('Importing a module script failed')
//   ) {
//     if (!to?.fullPath)
//       window.location.reload()
//     else
//       window.location.href = to.fullPath
//   }
// })

router.beforeEach((to, from, next) => {
  // if (to.name === 'index' && window.WEB_CONFIG.indexOpenStatus === 0) {
  //   location.href = location.origin
  //   return
  // }

  if (!Cookie.get('account_id') && to.meta.needLogin) {
    next(`/login?redirect=${encodeURIComponent(window.location.href)}`)
    return
  }
  if (
    Cookie.get('account_id')
    && ['login', 'register', 'forgetPass'].includes(to.name as string)
  ) {
    location.href = location.origin

    return
  }
  next()
})
router.afterEach((to) => {
  if (
    !['exchange-baseToken-quoteToken', 'futures-symbolId'].includes(
      to.name as string
    )
  ) {
    const configStore = useConfigStore()
    useTitle(configStore.indexConfig?.title)
  }
})

export default router
