import type {
  ChristmasLotteryType,
  ChristmasRecordType,
  CodeType,
  CustomInviteKycLevel,
  DeductionStatus,
  ExperienceStatus,
  FollowScaleTypes,
  FunctionType,

  FutureOrderType,
  FutureTimeInForce,
  GlobalNoticeStatus,
  GuideConfigType,
  IEOActivityType,
  IEOBasicInfoStatus,
  IEOOrderStatus,
  IEOProjectType,
  IEOStatus,
  InnerWithdrawType,
  KycAuthStatusType,
  LoginShowStatus,
  NoticeOpenStatus,
  NoticeReadStatus,
  PageColorType,
  PopupFrequency,
  PriceAlarmFrequency,
  PriceAlarmType,
  QrCodeStatus,
  RegisterOptions,
  RegisterType,
  SpotOrderStatus,
  StrategyGridType,
  StrategyOrderStatus,
  StrategyPositionMode,
  StrategySide,
  StrategyType,
  SubAccountType,
  TakeApplyFutureStatus,
  TakeApplyKYCStatus,
  TakeUserSettingStatus,
  UserActionTypes,
  WelfareActivityType,
  WelfareCenterTaskStatus,
  WelfareRewardType,
  WelfareStatus,
  WelfareType
} from './enums'
import type { JSONString } from './utils'

import type { NoticeType, OrderSide, SpotOrderType } from '~/types/enums'
import type { LoginHistoryStatus } from '@/types/enums'

export interface WebConfig {
  orgId: number
  responseRandomKey: string
  domains: string
  registerOption: RegisterOptions
  supportLanguages: SupportLanguage[]
  realtimeInterval: string
  page: Page
  checkInviteCode: boolean
  checkCompliance: boolean
  checkDepositKyt: boolean
  checkWithdrawKyt: boolean
  customKV: CustomKV
  functions: Functions
  kycCardType: KycCardType[]
  kycLevel: KycLevel[]
  exploreToken: string[]
  quoteToken: QuoteToken[]
  customQuoteToken: CustomQuoteToken[]
  marginQuoteToken: MarginQuoteToken[]
  token: Token[]
  symbol: QuoteTokenSymbol[]
  marginSymbol: QuoteTokenSymbol2[]
  optionUnderlying: OptionUnderlying[]
  optionQuoteToken: any[]
  optionToken: any[]
  optionSymbol: any[]
  optionCoinToken: any[]
  futuresUnderlying: FuturesUnderlying[]
  futuresSymbol: FuturesSymbol[]
  futuresCoinToken: string[]
  recommendSymbols: RecommendSymbol[]
  riskIpDisabled: Fields
  otc_token: Otctoken[]
  indexNewVersion: boolean
  indexOpenStatus: 0 | 1
  contractHelpUrl: string
  colorTheme: ColorTheme[]
  colorScheme: {
    light: ColorScheme
    dark: ColorScheme
  }
  otcToken: Otctoken[]
  loginReg: LoginReg[]
  analytics: Analytics
}

export interface InviteConfig {
  invite_activity_rule_url: string
  invite_title_pic_pc: string
}

export interface ColorTheme {
  content: Content
  tabName: string
  type: number
  useModule: number
}

interface Content {
  primary: Primary
  secondary: Primary
  error: Primary
  success: Primary
  down: Primary
  up: Primary
}

interface Primary {
  main: string
  light: string
  dark: string
  contrastText: string
}

interface Otctoken {
  orgId: string
  tokenId: string
  tokenName: string
  minQuote: string
  maxQuote: string
  scale: number
  upRange: string
  downRange: string
}

interface RecommendSymbol {
  orgId: string
  exchangeId: string
  symbolId: string
  symbolName: string
  baseTokenId: string
  baseTokenName: string
  quoteTokenId: string
  quoteTokenName: string
  basePrecision: string
  quotePrecision: string
  minTradeQuantity: string
  minTradeAmount: string
  minPricePrecision: string
  digitMerge: string
  canTrade: boolean
  customOrder: number
  isAggregate: boolean
  indexRecommendOrder: number
  showStatus?: boolean
  category: number
  allowMargin?: boolean
  labelId: string
  allowPlan?: boolean
  baseTokenFutures?: BaseTokenFutures2
  firstLevelUnderlyingId?: string
  firstLevelUnderlyingName?: string
  secondLevelUnderlyingId?: string
  secondLevelUnderlyingName?: string
  isReverse?: boolean
}

interface BaseTokenFutures2 {
  tokenId: string
  displayTokenId: string
  issueDate: string
  settlementDate: string
  coinToken: string
  currency: string
  currencyDisplay: string
  contractMultiplier: string
  limitDownInTradingHours: string
  limitUpInTradingHours: string
  limitDownOutTradingHours: string
  limitUpOutTradingHours: string
  maxLeverage: string
  levers: string[]
  riskLimits: RiskLimit[]
  crossRiskLimits: RiskLimit[]
  overPriceRange: string[]
  marketPriceRange: string[]
  indexToken: string
  displayIndexToken: string
  marginPrecision: string
  displayUnderlyingId: string
}

export interface FuturesSymbol {
  orgId: string
  exchangeId: string
  symbolId: string
  symbolName: string
  baseTokenId: string
  baseTokenName: string
  quoteTokenId: string
  quoteTokenName: string
  basePrecision: string
  quotePrecision: string
  minTradeQuantity: string
  minTradeAmount: string
  minPricePrecision: string
  digitMerge: string
  canTrade: boolean
  customOrder: number
  baseTokenFutures: BaseTokenFutures
  liquidationPriceMax: string
  liquidationPriceMin: string
  firstLevelUnderlyingId: string
  firstLevelUnderlyingName: string
  secondLevelUnderlyingId: string
  secondLevelUnderlyingName: string
  isReverse: boolean
  isAggregate: boolean
  indexRecommendOrder: number
  category: number
  labelId: string
  showStatus: boolean
  /**
   * 是否允许策略交易
   */
  enableTactics: boolean
  label: Fields
  iconUrl: string
}

export interface BaseTokenFutures {
  tokenId: string
  displayTokenId: string
  issueDate: string
  settlementDate: string
  coinToken: string
  currency: string
  currencyDisplay: string
  contractMultiplier: string
  limitDownInTradingHours: string
  limitUpInTradingHours: string
  limitDownOutTradingHours: string
  limitUpOutTradingHours: string
  maxLeverage: string
  levers: string[]
  riskLimits: RiskLimit[]
  crossRiskLimits: RiskLimit[]
  overPriceRange: string[]
  marketPriceRange: string[]
  indexToken: string
  displayIndexToken: string
  marginPrecision: string
  displayUnderlyingId: string
}

export interface RiskLimit {
  riskLimitId: string
  riskLimitAmount: string
  maintainMargin: string
  initialMargin: string
  side: string
}

interface FuturesUnderlying {
  id: string
  name: string
  parentId: string
  tag: string
  secondLevels: SecondLevel[]
}

interface SecondLevel {
  id: string
  name: string
  parentId: string
  tag: string
}

interface OptionUnderlying {
  id: string
  name: string
  secondLevels: any[]
}

export interface Token {
  orgId: string
  tokenId: string
  tokenName: string
  tokenFullName: string
  iconUrl: string
  allowWithdraw: boolean
  allowDeposit: boolean
  isHighRiskToken: boolean
  baseTokenSymbols: QuoteTokenSymbol2[]
  isEOS: boolean
  tokenType: string
  usdtValue: string
  needAddressTag: boolean
  needKycQuantity: string
  customOrder: number
  chainTypes: ChainType[]
  displayChainName?: string
}

interface ChainType {
  chainType: string
  allowDeposit: boolean
  allowWithdraw: boolean
}

interface MarginQuoteToken {
  orgId: string
  tokenId: string
  tokenName: string
  customOrder: number
  status: number
  quoteTokenSymbols: QuoteTokenSymbol2[]
}

interface QuoteTokenSymbol2 {
  orgId: string
  exchangeId: string
  symbolId: string
  symbolName: string
  baseTokenId: string
  baseTokenName: string
  quoteTokenId: string
  quoteTokenName: string
  basePrecision: string
  quotePrecision: string
  minTradeQuantity: string
  minTradeAmount: string
  minPricePrecision: string
  digitMerge: string
  canTrade: boolean
  customOrder: number
  isAggregate: boolean
  indexRecommendOrder: number
  showStatus: boolean
  category: number
  allowMargin: boolean
  labelId: string
  allowPlan: boolean
}

interface CustomQuoteToken {
  orgId: string
  tokenId: string
  tokenName: string
  customOrder: number
  status: number
  quoteTokenSymbols: QuoteTokenSymbol[]
}

interface QuoteToken {
  orgId: string
  tokenId: string
  tokenName: string
  iconUrl: string
  customOrder: number
  baseTokenSymbols: any[]
  quoteTokenSymbols: QuoteTokenSymbol[]
}

export interface QuoteTokenSymbol {
  orgId: string
  exchangeId: string
  symbolId: string
  symbolName: string
  baseTokenId: string
  baseTokenName: string
  quoteTokenId: string
  quoteTokenName: string
  basePrecision: string
  quotePrecision: string
  minTradeQuantity: string
  minTradeAmount: string
  minPricePrecision: string
  digitMerge: string
  canTrade: boolean
  customOrder: number
  isAggregate: boolean
  indexRecommendOrder: number
  showStatus: boolean
  category: number
  allowMargin: boolean
  labelId: string
  label: Fields
  allowPlan: boolean
}

interface KycLevel {
  levelId_: number
  levelName_: string
  precondition_: string
  memo_: string
  displayLevel_: string
  memoizedIsInitialized: number
  unknownFields: UnknownFields
  memoizedSize: number
  memoizedHashCode: number
}

interface UnknownFields {
  fields: Fields
  fieldsDescending: Fields
}

interface Fields { }

interface KycCardType {
  key: number
  value: string
}

export interface SupportLanguage {
  lang: string
  text: string
  icon: string
  prefix: string
  suffix: string
  jsLoadUrls: string[]
}

interface Functions {
  [FunctionType.MARGIN]: boolean
  [FunctionType.USER_LEVEL]: boolean
  [FunctionType.BONUS]: boolean
  [FunctionType.EXCHANGE]: boolean
  [FunctionType.INVITE]: boolean
  [FunctionType.RED_PACKET]: boolean
  [FunctionType.SHOW_SUB_ACCO]: boolean
  [FunctionType.FUTURES]: boolean
  [FunctionType.OTC]: boolean
  [FunctionType.PROFIT_LOSS]: boolean
  [FunctionType.FOLLOW]: boolean
  [FunctionType.STRATEGY]: boolean
  [FunctionType.IEO]: boolean
}

interface CustomKV {
  loginReg: LoginReg[]
  analytics: Analytics
}

interface Analytics {
  baidu: string
  google: string
}

interface LoginReg {
  source: string
  system: boolean
  regPage: RegPage
  loginPage: LoginPage
}

interface LoginPage {
  wrapBackground: string
}

interface RegPage {
  type: number
  wrapBackground: string
  layoutBackground: string
}

interface Page {
  index: string
}

export interface SocialMedia {
  brokerId: number
  darkDefaultLogo: string
  darkSelectedLogo: string
  defaultLogo: string
  detailList: DetailList[]
  locale: string
  platform: number
  selectedLogo: string
}

export interface DetailList {
  isBlank: number
  title: string
  url: string
}
export interface IndexConfig {
  orgId: string
  logo: string
  favicon: string
  copyright: string
  zendesk: string
  shares: Share[]
  title: string
  keywords: string
  description: string
  banners: Banner[]
  smallBanners: any[]
  socialMedia: SocialMedia[]
  announcements: Announcement[]
  announcementMoreUrl: string
  userAgreement: string
  userOptionAgreement: string
  userFuturesAgreement: string
  privacyAgreement: string
  videoVerifyAgreement: string
  customerService: string
  optionCustomerService: string
  futuresCustomerService: string
  futuresOpenWithAnswer: boolean
  optionOpenWithAnswer: boolean
  footConfigList: FootConfigList[]
  headConfigList: HeadConfigList[]
  shareConfig: ShareConfig
  indexModules: IndexModule[]
  logoUrl: string
  imageConfig: Record<'dark' | 'light', Partial<ImageConfig>>
  indexConfig: NewIndexConfig.Config
}

interface ImageConfig {
  auditFailed: string
  baseKyc: string
  brokerId: number
  email: string
  fishingCode: string
  fishingExample: string
  google: string
  highKyc: string
  id: number
  kyc: string
  kyc2Fail: string
  kyc2Success: string
  kyc2Verifying: string
  kycFailed: string
  kycPass: string
  mobile: string
  name: string
  password: string
  tradePassword: string
  underReview: string
}

interface Announcement {
  title: string
  detail: string
  isDirect: boolean
  directUrl: string
  publishTime: string
  sort: number
}
interface IndexModule {
  moduleName: string
  content?: Content | Content2 | Content3 | number
}

interface Content3 {
  title: string
  image: string
  background: string
  desc: string
  list: List[]
}

interface List {
  name: string
  link: string
  image: string
  tag: string
}

interface Content2 {
  featureTitle: string
  featureDesc: string
  background: string
  image: string
  features: Feature[]
}

export interface DownloadModuleContent {
  image: string
  background: string
  title: string
  list: List[]
}

interface Feature {
  image: string
  title: string
  description: string
  index: number
}

interface Content {
  title: string
  desc: string
  image: string
  background: string
  link: string
}

interface ShareConfig {
  logoUrl: string
  watermarkImageUrl: string
  title: string
  description: string
  openUrl: string
  openUrlImgBase64: string
  contractProfitShareTitles: any[]
  contractLossShareTitles: any[]
  contractZeroShareTitles: any[]
}

export interface HeadConfigList {
  nofollow: boolean
  blank: boolean
  system: boolean
  children: Child[]
  link: string
  img?: string
  text: string
  status?: boolean
  teamVisible?: boolean
  teamUids?: string[]
  tag?: string
  color?: string
  loginShow: LoginShowStatus
}

interface Child {
  nofollow: boolean
  blank: boolean
  link: string
  text: string
  img?: string
  desc?: string
}

export interface FootConfigList {
  caption: string
  items: Item[]
}

interface Item {
  nofollow: boolean
  blank: boolean
  children: any[]
  link: string
  text: string
}

interface Banner {
  imgUrl: string
  h5ImageUrl: string
  isDirect: boolean
  directUrl: string
  sort: number
}

interface Share {
  shareKey: string
  iconUrl: string
  shareUrl: string
}

export interface BaseInfo {
  userId: string
  registerType: RegisterType
  mobile: string
  nationalCode: string
  email: string
  bindGA: boolean
  bindTradePwd: boolean
  bindPassword: boolean
  userType: number
  verifyStatus: number
  kycLevel: number
  kycVerifyStatus: number
  displayLevel: string
  accounts: any[]
  defaultAccountId: string
  registerDate: string
  openOption: boolean
  openFuture: boolean
  openMargin: boolean
  favorites: Favorite[]
  source: string
  isAgent: number
  customLabelList: any[]
  levelNames: string[]
  leader: number
  isHobbit: number
  antiPhishingCode: string
  isComplianceVerify: boolean
  lastLoginDate: string
  lastLoginIp: string
  platfrom: string
}

export interface UserOpenInfo {
  photo: string
  nickName: string
  introduce: string
  isModify?: boolean
}

export interface Favorite {
  id: string
  exchangeId: string
  symbolId: string
}

export interface ProfitInfo {
  totalAsset: string
  todayProfit: string
  todayProfitRate: string
  cumulativeProfit: string
  cumulativeProfitRate: string
  cumulativeProfitRateList: CumulativeProfitRateList[]
  assetTrendList: CumulativeProfitRateList[]
  cumulativeProfitList: CumulativeProfitRateList[]
  profitList: CumulativeProfitRateList[]
  /**
   * 合约七日收益
   */
  futuresWeekProfit: string
  /**
   * 合约七日收益率
   */
  futuresWeekProfitRate: string
  /**
   * 合约累计收益
   */
  futuresCumulativeProfit: string
  /**
   * 合约累计收益率
   */
  futuresCumulativeProfitRate: string
}

interface CumulativeProfitRateList {
  date: string
  profit: string
}

interface SubAccount {
  accountId: string
  accountName: string
  accountType: SubAccountType
  accountIndex: number
  canTrade: boolean
  canDeposit: boolean
  canWithdraw: boolean
  authorizedOrg: boolean
  isForbid: number
  forbidStartTime: string
  forbidEndTime: string
}

export type SubAccountList = SubAccount[]

export interface AssetDistribution {
  coinAsset?: string
  financeAsset?: string
  futuresCoinAsset?: string
  subAccountAssert?: string
  totalAsset?: string
  balance?: AssetBalance[]
  kLines: KLine[]
  winRate?: string
  profitLoss?: string
  totalProfit?: string
  totalLoss?: string
  profitDays?: string
  LossDays?: string
  steadyDays?: string
  avgBuyPrice?: string
  avgSellPrice?: string
  totalBuy?: string
  totalSell?: string
}

interface UnknownFields {
  fields: Fields
  fieldsDescending: Fields
}

interface Fields { }
interface KLine {
  id_: string
  symbol_: string
  bTCChange_: string
  memoizedIsInitialized: number
  unknownFields: UnknownFields
  memoizedSize: number
  memoizedHashCode: number
}

interface AssetBalance {
  accountId_: string
  tokenId_: string
  tokenName_: string
  total_: string
  free_: string
  locked_: string
  btcValue_: string
  isEos_: boolean
  allowDeposit_: boolean
  allowWithdraw_: boolean
  lastUpdated_: string
  usdtValue_: string
  position_: string
  availablePosition_: string
  lockedPosition_: string
  balanceCreateAt_: string
  balanceUpdateAt_: string
  positionCreateAt_: string
  positionUpdateAt_: string
  memoizedIsInitialized: number
  unknownFields: UnknownFields
  memoizedSize: number
  memoizedHashCode: number
}
export interface UserSetting {
  userId: string
  commonConfig: JSONString<UserCommonConfig>
  updated: string
  created: string
}
export interface UserCommonConfig {
  quickCloseConfirm: boolean
  lang: string
  unit: string
  up_down: PageColorType
}

export interface UserTakeConfig {
  userId: string
  accountId: string
  symbolIdList: string[]
  drawScale: string
  positionType: number
  transactionType: number
  followCount: number
  takeProfit: string
  followCountLimit: number
  isTakeUser: 0 | 1
  totalFollowAmount: string
}
export interface FollowerInfo {
  userId: string
  takeUserId: string
  name: string
  takeName: string
  takePhoto: string
  followCount: number
  followAmount: string
  cumulativeProfit: string
  profit: string
  symbolIdList: string[]
  followScale: string
  followLeverType: number
  longLeverage: string
  shortLeverage: string
  profitRate: string
  lossRate: string
}
/**
 * 带单广场-带单人列表
 */
export interface FollowSquareTakeOrderItem {
  followCount: number
  followCountLimit: number
  followProfit: string
  iconUrl: string
  isTake: boolean
  name: string
  symbolIdList: string[]
  takeDescribe: string
  totalProfit: string
  totalProfitRate: string
  userId: string
}

export interface TakeProfitDetails {
  userId: string
  takeUserId: string
  profit: string
  created: number
}
export interface CustexperienceSettings {
  webTitle: string
  webDescription: string
  webBackground: string
  webBackgroundColor: string
  appTitle: string
  appDescription: string
  appBackground: string
}

export interface IEOSettings {
  /**
   * h5背景图片
   */
  h5Background: string
  /**
   * 标题
   */
  title: string
  /**
   * pc背景图片
   */
  webBackground: string
}

export interface CustWelfareActivitySettings {
  /**
   * app图片
   */
  appBackgroundUrl?: string
  /**
   * app头图
   */
  appHeadPhotoUrl?: string
  /**
   * app跳转链接
   */
  appJumpLink: string
  /**
   * web图片1
   */
  webBackgroundUrl1: string
  /**
   * web图片2
   */
  webBackgroundUrl2: string
  /**
   * web头图
   */
  webHeadPhotoUrl: string
  /**
   * web1跳转链接
   */
  webJumpLink1: string
  /**
   * web2跳转链接
   */
  webJumpLink2: string
}

export interface PlatformData {
  'cust.platformData'?: CustplatformData
  'cust.experienceSettings'?: CustexperienceSettings
  'cust.welfareCenterSettings'?: CustexperienceSettings
  'cust.rewardCenterActivitySettings'?: CustWelfareActivitySettings
  'cust.kycSettings'?: CustkycSettings
  'cust.ieoSettings'?: IEOSettings
}

interface CustplatformData {
  list: PlatformListItem[]
}

export interface PlatformListItem {
  text: string
  value: string
  key: string
}

export interface TakeApplyStatus {
  kycStatus: TakeApplyKYCStatus
  openFuture: TakeApplyFutureStatus
}

export interface FollowPicConfig {
  language: string
  iconUrl?: string
  shareUrl?: string
}

export interface CoinAssetInfo {
  tokenId: string
  tokenName: string
  total: string
  free: string
  locked: string
  btcValue: string
  usdtValue: string
  position: string
  balanceCreatedAt: string
  balanceUpdatedAt: string
  /**
   * 今日收益盈亏
   */
  todayProfit: string
}

export interface CoinProfitInfo extends CoinAssetInfo {
  /** 平均买入价格 */
  bugAvgPrice: string
  /** 持仓成本 */
  positionCost: string
  /** 累计收益 */
  cumulativeProfit: string
  /** 累计收益率 不需要乘100 */
  cumulativeProfitRate: string
}

export interface CoinProfitInfo extends CoinAssetInfo {
  /** 平均买入价格 */
  bugAvgPrice: string
  /** 持仓成本 */
  positionCost: string
  /** 累计收益 */
  cumulativeProfit: string
  /** 累计收益率 不需要乘100 */
  cumulativeProfitRate: string
}

export interface SwapRecord {
  fromTokens: string
  toTokens: string
}

export interface ExperienceCouponInfo {
  /**
   * 用户账户id
   */
  accountId: number
  /**
   * 活动类型
   */
  activityType: number
  /**
   * 空头id
   */
  airdropId: number
  /**
   * 空投时间
   */
  airdropTime: number
  /**
   * 券商id
   */
  brokerId: number
  /**
   * 创建时间
   */
  createdAt: number
  /**
   * 券id
   */
  experienceId: number
  /**
   * 领取到期时间
   */
  expirationTime: number
  /**
   * 操作人
   */
  operator: string
  /**
   * 体验金券状态1:未领取、2:已领取、3:已过期、4:已回收
   */
  status: ExperienceStatus
  /**
   * 空投领取期限，秒数
   */
  timeLimit: string
  /**
   * 标题
   */
  title: string
  /**
   * 币种
   */
  tokenId: string
  /**
   * 数量
   */
  total: string
  /**
   * 更新时间/处理时间
   */
  updatedAt: number
  /**
   * 用户id
   */
  userId: number
}

export interface DeductionCouponInfo {
  accountId: number
  /**
   * 活动类型
   */
  activityType: number
  /**
   * 空投id
   */
  airdropId: number
  /**
   * 空投时间
   */
  airdropTime: number
  /**
   * 可用数量
   */
  available: string
  /**
   * 券商id
   */
  brokerId: string
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 券id
   */
  deductionId: number
  /**
   * 到期时间
   */
  expirationTime: number
  /**
   * 操作人
   */
  operator: number
  /**
   * 券状态，1:可用、2.用尽、3:已过期、4:已回收
   */
  status: DeductionStatus
  /**
   * 可用时间，秒
   */
  timeLimit: number
  /**
   * 币种
   */
  tokenId: string
  /**
   * 数量
   */
  total: string
  /**
   * 更新时间/处理时间
   */
  updatedAt: string
  /**
   * 用户id
   */
  userId: number
}
export interface GridPosition {
  /**
   * 标识栅格元素位于第几列，需为自然数
   */
  x: number
  /**
   * 标识栅格元素位于第几行，需为自然数
   */
  y: number
  /**
   * 标识栅格元素的初始宽度，值为colWidth的倍数
   */
  w: number
  /**
   * 标识栅格元素的初始高度，值为rowHeight的倍数
   */
  h: number
  /**
   * 栅格中元素的ID
   */
  i: string
  /**
   * 是否静态不允许拖拽
   */
  static: boolean
}
/**
 * 用户的可划转资产
 */
export interface UserTransferAssets {
  success: boolean
  /**
   * 可划转余额
   */
  amount: string
  /**
   * 体验金余额
   */
  experienceBalance: string
}
export interface FundingRate {
  /**
   * 币种名称
   */
  tokenId: string
  /**
   * 服务器时间
   */
  curServerTime: string
  /**
   * 最后结算时间
   */
  lastSettleTime: string
  /**
   * 预测资金费率
   */
  settleRate: string
  /**
   * 下次结算时间
   */
  nextSettleTime: string
  /**
   * 资金费率
   */
  fundingRate: string
}
export interface ContractAssets {
  tokenId: string
  tokenName: string
  total: string
  availableMargin: string
  positionMargin: string
  orderMargin: string
  experienceBalance: string
  deductionBalance: string
  canTransfer: string
  totalEquity: string
  unrealisedPnl: string
  walletBalance: string
  /**
   * 今日收益
   */
  todayProfit: string
}
export interface FutureTradeable {
  tokenId: string
  longTotal: string
  longAvailable: string
  shortTotal: string
  shortAvailable: string
  profitLoss: ProfitLoss
}

export interface ProfitLoss {
  coinAvailable: string
  realAvailable: string
  margin: string
  orderMargin: string
  realisedPnl: string
  unrealisedPnl: string
}

export interface FutureOrderSetting {
  symbolId: string
  orderFee: OrderFee
  orderSetting: OrderSetting
  riskLimit: RiskLimit[]
}

export interface OrderSetting {
  isPassiveOrder: boolean
  timeInForce: FutureTimeInForce
  isConfirm: boolean
  isAutoAddMargin: boolean
  addMarginLimit: string
}

export interface OrderFee {
  symbolId: string
  symbolName: string
  takerFeeRate: string
  makerFeeRate: string
}

export interface LeverageSetting {
  leverage: number
  symbol_id: string
  isCross: boolean
  leverageLong: number
  leverageShort: number
}

export interface FutureHistoryTrade {
  time: string
  tradeId: string
  orderId: string
  accountId: string
  symbolId: string
  symbolName: string
  baseTokenId: string
  baseTokenName: string
  quoteTokenId: string
  quoteTokenName: string
  price: string
  quantity: string
  amount: string
  feeTokenId: string
  feeTokenName: string
  fee: string
  makerRebate: string
  type: string
  side: FutureOrderType
  executedAmount: string
  priceType: string
  liquidationType: string
  pnl: string
  experienceFee: string
  deductionFee: string
  realFee: string
}

export interface FutureOrderMatchInfo {
  time: string
  tradeId: string
  orderId: string
  accountId: string
  symbolId: string
  symbolName: string
  baseTokenId: string
  baseTokenName: string
  quoteTokenId: string
  quoteTokenName: string
  price: string
  quantity: string
  amount: string
  feeTokenId: string
  feeTokenName: string
  fee: string
  makerRebate: string
  type: string
  side: FutureOrderType
  executedAmount: string
  priceType: string
  liquidationType: string
  pnl: string
  experienceFee: string
  deductionFee: string
  realFee: string
}

export type GridItemNames =
  | 'info'
  | 'chart'
  | 'order'
  | 'handicap'
  | 'lastTrade'
  | 'orderCreate'
  | 'assets'
  | 'intro'
  | 'quote'
export type StrategyGridItemNames = | 'info'
  | 'chart'
  | 'order'
  | 'handicap'
  | 'lastTrade'
  | 'orderCreate'

export interface IGridItem extends GridPosition {
  i: GridItemNames
  refName?: string
  resizable?: boolean
  draggable?: boolean
  dragAllowFrom?: string
  dragIgnoreFrom?: string
  resizeIgnoreFrom?: string
  isResizable?: boolean
  minW?: number
  maxW?: number
  minH?: number
  maxH?: number
  moved?: boolean
}
export interface IStrategyGridItem extends GridPosition {
  i: StrategyGridItemNames
  refName?: string
  resizable?: boolean
  draggable?: boolean
  dragAllowFrom?: string
  dragIgnoreFrom?: string
  resizeIgnoreFrom?: string
  isResizable?: boolean
  minW?: number
  maxW?: number
  minH?: number
  maxH?: number
}

/**
 * v3弃用
 */
// export interface GeetestData {
//   success: number
//   new_captcha: boolean
//   challenge: string
//   gt: string
// }

export interface QrCodeScanResult {
  qrCodeStatus: QrCodeStatus
  qrCodeTimeLeft: number
  userInfo: BaseInfo
}

export interface NoticeItem {
  content: string
  createAt: string
  id: string
  messageId: string
  readStatus: NoticeReadStatus
  pushUrl: string
  pushUrlType: number
  title: string
}

export interface NoticeTypeReadStatus {
  type: NoticeType
  readStatus: NoticeReadStatus
}
export interface NoticeDetail {
  content: string
  createAt: string
  title: string
}
export interface SpotMatchInfo {
  time: string
  tradeId: string
  orderId: string
  accountId: string
  symbolId: string
  symbolName: string
  baseTokenId: string
  baseTokenName: string
  quoteTokenId: string
  quoteTokenName: string
  price: string
  quantity: string
  amount: string
  feeTokenId: string
  feeTokenName: string
  fee: string
  makerRebate: string
  type: Uppercase<SpotOrderType>
  side: OrderSide
  matchOrgId: string
  matchUserId: string
  matchAccountId: string
}
export interface SpotPlanMatchInfo {
  planOrder: SpotPlanMatchPlanOrder
  order: SpotPlanMatchOrder
}

export interface SpotPlanMatchOrder {
  time: string
  orderId: string
  accountId: string
  clientOrderId: string
  symbolId: string
  symbolName: string
  baseTokenId: string
  baseTokenName: string
  quoteTokenId: string
  quoteTokenName: string
  price: string
  origQty: string
  executedQty: string
  executedAmount: string
  avgPrice: string
  type: Uppercase<SpotOrderType>
  side: OrderSide
  fees: any[]
  status: SpotOrderStatus
  exchangeId: string
  updateTime: string
}

export interface SpotPlanMatchPlanOrder {
  time: string
  orderId: string
  accountId: string
  clientOrderId: string
  symbolId: string
  symbolName: string
  baseTokenId: string
  baseTokenName: string
  quoteTokenId: string
  quoteTokenName: string
  price: string
  origQty: string
  type: Uppercase<SpotOrderType>
  side: OrderSide
  status: SpotOrderStatus
  exchangeId: string
  orgId: string
  triggerPrice: string
  triggerTime: string
  quotePrice: string
  executedOrderId: string
  executedPrice: string
  executedQty: string
  updateTime: string
  timeInForce: string
}

export interface CalculatorResult {
  makerCloseFee: string
  makerOpenFee: string
  orderMargin: string
  profit: string
  profitRate: string
  takerCloseFee: string
  takerOpenFee: string
  tokenId: string
  liquidationPrice: string
}
export type CalculatorProfitResult = Omit<CalculatorResult, 'liquidationPrice'>
export type CalculatorLiquidationPriceResult = Pick<
  CalculatorResult,
  'liquidationPrice'
>

export interface UserAward {
  /**
   * 抵扣金奖励金额
   */
  deductionRewardTotal: string
  /**
   * 体验金奖励金额
   */
  experienceRewardTotal: string
}

export interface PopupAd {
  /**
   * 不唯一id
   */
  bannerId: number
  /**
   * 跳转链接
   */
  directUrl: string
  /**
   * 弹窗频率，1每日首次进入 2每次进入 3只弹出一次
   */
  frequency: PopupFrequency
  /**
   * h5图片链接
   */
  h5ImageUrl: string
  /**
   * 唯一id
   */
  id: number
  /**
   * 图片链接
   */
  imgUrl: string
  /**
   * 是否跳转，ture跳 false不跳
   */
  isDirect: boolean
  /**
   * 排序序号
   */
  sort: number
}
export interface ColorScheme {
  id?: string
  primary: string
  success: string
  warning: string
  error: string
  background: string
  exchangePageBg: string
  inputBg: string
  disabledBg: string
  pageBg: string
  title: string
  subTitle: string
  mainTitle: string
  splitLine: string
  grow: string
  down: string
  sliderBg: string
  sliderForegroundColor: string
  name: string
  brokerId: string
}

export interface TokenInfo {
  tokenId: string
  tokenName: string
  maxQuantitySupplied: string
  currentTurnover: string
  officialWebsiteUrl: string
  whitePaperUrl: string
  publishTime: string
  exploreUrl: string
  description?: string
}

export namespace NewIndexConfig {
  export interface Config {
    dark: ConfigItem[]
    light: ConfigItem[]
  }
  export enum LayoutMode {
    IMAGE_TOP = 1,
    IMAGE_BOTTOM = 2,
    IMAGE_LEFT_TOP_RIGHT_BOTTOM = 3,
    IMAGE_LEFT_BOTTOM_RIGHT_TOP = 4
  }
  export enum OpenStatus {
    CLOSE,
    OPEN
  }
  export enum RegisterSolo {
    IN_BANNER,
    SOLO
  }
  /**
   * 模块类型，1头部 2banner区 3一分钟注册 4币对行情 5优势特点 6帮助 7下载 8自定义1 9自定义2 10自定义3
   */
  export enum ModuleType {
    HEADER = 1,
    BANNER,
    REGISTER,
    MARKET,
    ADVANTAGE,
    HELP,
    DOWNLOAD,
    CUSTOM1,
    CUSTOM2,
    CUSTOM3,
    VIDEO,
    PARTNER
  }

  export interface ConfigItem {
    brokerId: string
    /**
     * 配置内容列表
     */
    contentList: ContentList[]
    /**
     * 下载图标
     */
    downloadLogo: List[]
    /**
     * 优势特点布局模式，1.图片都在上  2.图片都在下  3.图片左上右下  4.图片左下右上
     */
    layoutMode: LayoutMode
    /**
     * 配色模式名称，dark light
     */
    name: 'dark' | 'light'
    /**
     * 打开状态，0关闭 1打开
     */
    openStatus: OpenStatus
    /**
     * 一分钟注册模块独立，0不独立 1独立
     */
    registerSolo: RegisterSolo
    /**
     * 排序
     */
    sort: number
    /**
     * 状态，0预览 1线上
     */
    status: number
    /**
     * 模块类型，1头部 2banner区 3一分钟注册 4币对行情 5优势特点 6帮助 7下载 8自定义1 9自定义2 10自定义3
     */
    type: ModuleType
  }

  export interface ContentIntroduction {
    background: string
    image: string
    list: { title?: string, desc?: string, image?: string, link?: string }[]
  }
  export interface ContentList {
    content: {
      background?: string
      desc?: string
      image?: string
      imageSmall?: string
      title?: string
      leftPart?: ContentIntroduction
      rightPart?: ContentIntroduction
      link?: string
      list?: {
        title?: string
        image?: string
        link?: string
        poster?: string
        linkMore?: string
        desc?: string
        subTitle?: string
      }[]
    }
    locale: string
  }
}

export interface UserTakeInfo {
  accountId: string
  /**
   * 抽佣比例
   */
  drawScale: string
  /**
   * 跟随人数
   */
  followCount: number
  /**
   * 累计跟单总收益
   */
  followProfit: string
  /**
   * 跟随人数上限
   */
  followCountLimit: string
  /**
   * 昵称
   */
  name: string
  /**
   * 头像
   */
  photo: string
  /**
   * 收益额
   */
  pnl: string
  /**
   * 收益率
   */
  profitRate: string
  /**
   * 简介
   */
  takeDescribe: string
  /**
   * 是否跟单中
   */
  isTake: boolean
  userId: string
}

export interface UserTakeTradeData {
  /**
   * aum
   */
  totalFollowAmount: string
  /**
   * 累计跟单人数
   */
  totalFollowCount: number
  /**
   * 交易数量
   */
  tradeCount: number
  userId: string
  /**
   * 胜率已乘百分比
   */
  winRate: string
}

export interface TakeUserSetting {
  /**
   * 跟单人开关
   */
  followUserOpen: TakeUserSettingStatus
  /**
   * 历史带单开关
   */
  historyOpen: TakeUserSettingStatus
  /**
   * 当前带单开关
   */
  positionOpen: TakeUserSettingStatus
  /**
   * 交易开关
   */
  tradeOpen: TakeUserSettingStatus
  userId: string
}

export interface InvitInfoDTO {
  bonusCoin: number
  bonusPoint: number
  directRate: number
  hide: number
  indirectRate: number
  inviteCount: number
  inviteDirectVaildCount: number
  inviteIndirectVaildCount: number
  inviteLevel: number
  inviteVaildCount: number
  userId: string
}

export interface InvitationInfo {
  inviteCode: string
  inviteInfoDTO: InvitInfoDTO
}

export interface UserPosterInfo {
  /**
   * 累计跟随人数
   */
  followCount: string
  /**
   * 跟随者累计收益
   */
  followProfit: number
  /**
   * 邀请码
   */
  inviteCode: string
  /**
   * 昵称
   */
  name: string
  /**
   * 头像
   */
  photo: string
  /**
   * 总收益额
   */
  pnl: number
  /**
   * 总收益率
   */
  profitRate: number
  /**
   * 分享海报1
   */
  sharePost1Url: string
  /**
   * 分享海报2
   */
  sharePost2Url: string
  /**
   * uid
   */
  userId: number
}

export interface GlobalNotice {
  content: string
  id: string | number
  barId: string | number
  page: string
  status: GlobalNoticeStatus
}

export interface FollowCommonConfig {
  /**
   * 结束跟随投入
   */
  endFollowAmount: number
  /**
   * 结束固定额度
   */
  endFollowQuota: number
  /**
   * 结束跟单比例 返回百分比
   */
  endFollowScale: number
  /**
   * 结束止损率 返回百分比
   */
  endLossRate: number
  /**
   * 结束止盈率 返回百分比
   */
  endProfitRate: number
  /**
   * 邀请反佣比例
   */
  inviteProfitRate: number
  /**
   * 券商ID
   */
  orgId: number
  /**
   * 起始跟随投入
   */
  startFollowAmount: number
  /**
   * 起始固定额度
   */
  startFollowQuota: number
  /**
   * 起始跟单比例 返回百分比
   */
  startFollowScale: number
  /**
   * 起始止损率 返回百分比
   */
  startLossRate: number
  /**
   * 起始止盈率 返回百分比
   */
  startProfitRate: number
  /**
   * 交易数据更新时间
   */
  statisticalTime: number
}
export interface PriceAlarm {
  /**
   * 券商id
   */
  brokerId: string
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 当前价格
   */
  currentPrice: string
  /**
   * 交易所id
   */
  exchangeId: string
  /**
   * 频率
   */
  frequency: PriceAlarmFrequency
  /**
   * 主键id
   */
  id: string
  /**
   * 提醒类型
   */
  reminderType: PriceAlarmType
  /**
   * 提醒的值，价格 涨跌幅等 涨跌幅需要乘以100再显示
   */
  reminderValue: string
  /**
   * 币对
   */
  symbolId: string
  /**
   * 币对类型，1币币 2合约
   */
  symbolType: number
  /**
   * 修改时间
   */
  updatedAt: string
  /**
   * 用户id
   */
  userId: string
}

// 国家接口返回类型
export interface CountryItem {
  // id
  id: string
  allowBindMobile: number
  allowKyc: number
  allowLogin: number
  allowRegister: number
  // 国家名称
  countryName: string
  // 图标
  icon: string
  indexName: string
  // 国家编码
  nationalCode: string
  // 简称
  shortName: string
  // 用于select显示
  value?: string
  // 用于select显示
  label?: string
}

// 代理总览信息
export interface AgentInfo {
  coinAssets: string
  futuresAssets: string
  inviteNum: string
  isShowProfitLoss: boolean
  profitLoss: string
  // 合约分佣比例可配置最大值
  rate: string
  // 币币分佣比例可配置最大值
  rateCoin: string
  // 合约分佣比例配置间隔
  rateInterval: number
  // 币币分佣比例配置间隔
  rateIntervalCoin: number
  // 合约分佣比例可配置最小值
  rateMin: string
  // 币币分佣比例可配置最小值
  rateMinCoin: string
  selfRebate: number
  tokenAmount: string
  totalAssets: string
  yesterdayDepositNum: string
  yesterdayInviteNum: string
  yesterdayTokenAmount: string
  yesterdayWithdrawNum: string
}

export interface CreateStrategyBaseData {
  symbol_id: string
  /**
   * 策略类型
   */
  tactics_type: StrategyType
  /**
   * 策略方向
   */
  side: StrategySide
  /**
   * 持仓模式
   */
  position_mode_type: StrategyPositionMode
  /**
   * 杠杆
   */
  leverage: string
  /**
   * 触发价格
   */
  trigger_price?: string

  /**
   * 交易所ID
   */
  exchange_id: string
}
export type CreateStrategyParams<T> = CreateStrategyBaseData & T
export interface CreateGridParams {
  /**
   * 总投入金额 杠杆放大后
   */
  all_price: string
  /**
   * 最小价格
   */
  min_price: string
  /**
   * 最高价格
   */
  max_price: string
  /**
   * 网格类型
   */
  grid_type: StrategyGridType
  /**
   * 网格数量
   */
  grid_count: string
  /**
   * 网格利润
   */
  grid_profit?: string
  /**
   * 终止顶部价
   */
  stop_top_price?: string
  /**
   * 终止底部价
   */
  stop_low_price?: string
  /**
   * 保证金
   */
  margin: string
}

export interface CreateMartinParams {
  /**
   * 涨跌加仓比例
   */
  more_shares_ratio: string
  /**
   * 单次止盈百分比
   */
  stop_profit_ratio: string
  /**
   * 最多加仓次数
   */
  max_more_shares_count: string
  /**
   * 加仓保证金
   */
  more_shares_count_margin: string
  /**
   * 加仓价差倍数
   */
  more_shares_spread_multiple?: string
  /**
   * 加仓金额倍数
   */
  more_shares_price_multiple?: string
  /**
   * 止损比例
   */
  stop_loss_ratio?: string
  /**
   * 循环次数
   */
  loop_count?: string
  /**
   * 总投入
   */
  all_price: string
  /**
   * 保证金
   */
  first_margin: string
}

export interface StrategyOrderInfo {
  /**
   * 策略开始时间(毫秒)
   */
  begin_time: string
  /**
   * 策略创建时间
   */
  created: string
  /**
   * 运行时间，(秒)
   */
  elapsed_time: string
  /**
   * 策略结束时间(毫秒)
   */
  end_time: string
  /**
   * 交易所id
   */
  exchange_id: string
  /**
   * 初次下单保证金(马丁)
   */
  first_margin: string
  /**
   * 网格数(网格)
   */
  grid_count: number
  /**
   * 单网格利润
   */
  grid_profit?: string
  /**
   * 网格类型，1等差 2等比
   */
  grid_type: StrategyGridType
  /**
   * 策略id
   */
  id: string
  /**
   * 杠杆
   */
  leverage: number
  /**
   * 强平价格
   */
  liquidation_price: string
  /**
   * 循环次数, 0无限循环(马丁)
   */
  loop_count?: number
  /**
   * 投入保证金，初始保证金/投入保证金(合约/马丁)
   */
  margin: string
  /**
   * 最大加仓次数(马丁)
   */
  max_more_shares_count?: string
  /**
   * 最高价(网格)
   */
  max_price: string
  /**
   * 最低价(网格)
   */
  min_price: string
  /**
   * 加仓保证金(马丁)
   */
  more_shares_count_margin?: string
  /**
   * 加仓价格倍数(马丁)
   */
  more_shares_price_multiple?: string
  /**
   * 涨跌加仓比例(马丁)
   */
  more_shares_ratio: string
  /**
   * 加仓价差倍数(马丁)
   */
  more_shares_spread_multiple?: string
  /**
   * 仓位模式，1全部, 2逐仓, 3全仓
   */
  position_mode_type: number
  /**
   * 总盈亏/总收益
   */
  profit_amount: string
  /**
   * 已结收益
   */
  settled_profit: string
  /**
   * 方向，1买入开多, 2卖出开空
   */
  side: StrategySide
  /**
   * 策略状态，1待执行
   * 2待触发
   * 3执行中
   * 4关闭中
   * 5已关闭
   * 6已取消
   */
  status: StrategyOrderStatus
  /**
   * 止损百分比(马丁)
   */
  stop_loss_ratio?: string
  /**
   * 终止底部价格(网格)
   */
  stop_low_price: string
  /**
   * 单次止盈百分比(马丁)
   */
  stop_profit_ratio: string
  /**
   * 终止顶部价格(网格)
   */
  stop_top_price: string
  /**
   * 币对
   */
  symbol_id: string
  /**
   * 策略类型，1网格, 2马丁
   */
  tactics_type: StrategyType
  /**
   * 触发价格
   */
  trigger_price?: string
  /**
   * 未结收益
   */
  unsettled_profit: string
}

export interface StrategyAIParams {
  /**
   * 杠杆
   */
  leverage: number
  /**
   * 初次保证金
   */
  margin: number
  /**
   * 最大加仓次数(马丁)
   */
  maxMoreSharesCount: number
  /**
   * 加仓保证金(马丁)
   */
  moreSharesCountMargin: number
  /**
   * 加仓价格倍数(马丁)
   */
  moreSharesPriceMultiple: number
  /**
   * 涨跌加仓比例(马丁)
   */
  moreSharesRatio: number
  /**
   * 加仓价差倍数(马丁)
   */
  moreSharesSpreadMultiple: number
  /**
   * 仓位模式, 1全部, 2逐仓, 3全仓
   */
  positionModeType: StrategyPositionMode
  /**
   * 方向, 1买入开多, 2卖出开空
   */
  side: StrategySide
  /**
   * 单次止盈百分比(马丁)
   */
  stopProfitRatio: number
  /**
   * 币对ID
   */
  symbolId: string
}
/**
 * v4 极验成功返回的内容
 */
export interface GeetestResult {
  captcha_id: string
  captcha_output: string
  gen_time: string
  lot_number: string
  pass_token: string
}

/**
 * 已登录发送邮箱/手机验证码
 */
export interface LoginedSendVeifyParams extends GeetestResult {
  type: CodeType
  gee_version: string
  mobile?: string | number
  email?: string
  national_code?: number
}

/**
 * 发送提币验证码，二次验证
 */
export interface SendSecondWithdrawVerify extends GeetestResult {
  request_id: string
  gee_version: string
}

/**
 * 策略账户资产响应
 */
export interface StrategyAssetRes {
  /**
   * 币种
   */
  tokenId: string
  /**
   * 资产折合
   */
  tacticsAsset: string
  /**
   * 总投入
   */
  total: string
  /**
   * 总收益-运行中策略交易的总盈亏之和
   */
  profitAmount: string
  /**
   * 已结算收益-运行中策略交易已结算收益
   */
  settledProfit: string
  /**
   * 未结算收益-运行中策略交易未结算盈亏
   */
  unsettledProfit: string
}

export interface RateHistoryItem {
  id: string
  tokenId: string
  settleTime: string
  settleRate: string
}

export interface LoginHistoryItem {
  id: string
  ip: string
  region: string
  status: LoginHistoryStatus
  created: string
  platform: string
  system: string
}
/** 消息设置状态 */
export interface NoticeOpenStatusItem {
  switchType: NoticeType
  status: NoticeOpenStatus
}
/** 流水类型 */
export interface TurnoverItem {
  flowType: number
  flowName: string
}
/** 流水明细 */
export interface BalanceFlowItem {
  id: string
  accountId: string
  tokenId: string
  tokenName: string
  flowType: string
  change: string
  total: string
  created: string
  symbolId: string
}
export interface SubAccountAssets {
  accountId: string
  accountName: string
  accountType: number
  accountIndex: number
  canTrade: boolean
  canDeposit: boolean
  canWithdraw: boolean
  balance: SubAccountBalance[]
  btcValue: string
  usdtValue: string
}

export interface SubAccountBalance {
  tokenId: string
  tokenName: string
  total: string
  free: string
  locked: string
  totalEquity: string
  unrealisedPnl: string
}

export interface GuideConfig {
  moduleList: GuideConfigList[]
  /**
   * 页面的编码格式
   */
  language: string
  /**
   * 页面id
   */
  pageId: string
}

export interface GuideConfigList {
  /**
   * 背景色
   */
  backgroundColor?: string
  /**
   * 类别id
   */
  categoryId?: string
  /**
   * 类别名称
   */
  categoryName?: string
  /**
   * 描述
   */
  description?: string
  itemList?: GuideConfigItem[]
  /**
   * 固定配置
   */
  key: 'banner' | 'video' | 'category' | 'recommend'
  /**
   * 固定配置
   */
  sort: GuideConfigType
  /**
   * 主标题
   */
  titile?: string
  /**
   * 文章列表
   */
  articleList?: GuideArticleItem[]
}

export interface GuideConfigItem {
  /**
   * 文章多语言公共id
   */
  articleBaseId: string
  /**
   * 文章id
   */
  articleId: number
  /**
   * 封面图url
   */
  imagePath: string
  /**
   * 类型ID
   */
  categoryId?: string
  /**
   * 类型标题
   */
  categoryName?: string
  /**
   * 副标题
   */
  secondTitle?: string
  /**
   * 背景色
   */
  backgroundColor?: string
  /**
   * 背景图
   */
  backgroundImageUrl?: string
  /**
   * 描述
   */
  description?: string
  /**
   * 图片url
   */
  imageUrl: string
  /**
   * 语言
   */
  language: string
  /**
   * 链接url
   */
  linkUrl?: string
  /**
   * 标题
   */
  title?: string
  /**
   * 视频url
   */
  videoUrl?: string
  /**
   * 按钮链接
   */
  buttonDirectUrl?: string
  /**
   * 按钮文案
   */
  buttonDescription?: string
}

export interface WelfareListItem {
  /**
   * 新券商福利中心活动id,10.新手任务-首次充值，11.新手任务-现货交易，12.新手任务-合约交易，13.交易任务-合约交易，14.交易任务-跟单交易，15.交易任务-策略交易
   * 16. 限时任务
   */
  activity: WelfareActivityType
  /**
   * 奖励券类型{1: 体验金券, 2: 抵扣金券}
   */
  coupon: WelfareRewardType
  data: WelfareListItemData
  /**
   * 忽略
   */
  finishGear: number
  /**
   * 完成时间
   */
  finishTime: string
  /**
   * 活动类型 1.新手任务，2.用户任务 3:限时任务
   */
  listType: WelfareType
  /**
   * 奖励数量
   */
  quantity: string
  /**
   * 状态 1:未完成 2:已完成 3:已过期
   */
  status: WelfareStatus
}

export interface WelfareListItemData {
  /**
   * 新手充值量
   */
  depositAmount: string
  /**
   * 新手充值奖励比例
   */
  depositRewardRate: string
  /**
   * 跟单交易额
   */
  followAmount: string
  /**
   * 当前跟单交易额
   */
  followCurrentAmount: string
  /**
   * 跟单奖励
   */
  followReward: string
  /**
   * 新手合约交易额
   */
  futuresTradeAmount: string
  /**
   * 新手合约奖励比例
   */
  futuresTradeRewardRate: string
  /**
   * 当前交易-合约 合约交易额
   */
  ladderCurrentFuturesAmount: string
  /**
   * 一级合约-交易 完成状态 0:未完成 1:已完成
   */
  ladderFinish1: WelfareCenterTaskStatus
  /**
   * 二级合约-交易 完成状态 0:未完成 1:已完成
   */
  ladderFinish2: WelfareCenterTaskStatus
  /**
   * 限时充值任务状态
   */
  timeTransactionDepositFinish: WelfareStatus
  /**
   * 限时交易任务状态
   */
  timeTransactionAmountFinish: WelfareStatus
  /**
   * 交易-合约一级交易额
   */
  ladderFuturesAmount1: string
  /**
   * 交易-合约二级交易额
   */
  ladderFuturesAmount2: string
  /**
   * 交易-合约一级奖励
   */
  ladderFuturesReward1: string
  /**
   * 真实最大充值奖励
   */
  maxDepositReward: string
  /**
   * 真实最大新手合约奖励
   */
  maxFuturesTradeReward: string
  /**
   * 真实交易-合约二级最大奖励
   */
  maxLadderFuturesReward2: string
  /**
   * 真实最大新手现货交易奖励
   */
  maxSpotTradeReward: string
  /**
   * 真实最小新手合约奖励
   */
  minFuturesTradeReward: string
  /**
   * 真实交易-合约二级最小奖励
   */
  minLadderFuturesReward2: string
  /**
   * 真实最小新手现货交易奖励
   */
  minSpotTradeReward: string
  /**
   * 展示新手合约奖励
   */
  showFuturesTradeReward: string
  /**
   * 展示最大新手充值奖励
   */
  showMaxDepositReward: string
  /**
   * 展示交易-合约二级最大奖励
   */
  showMaxLadderFuturesReward2: string
  /**
   * 展示最小新手充值奖励
   */
  showMinDepositReward: string
  /**
   * 展示交易-合约二级最小奖励
   */
  showMinLadderFuturesReward2: string
  /**
   * 展示新手现货交易奖励
   */
  showSpotTradeReward: string
  /**
   * 新手现货交易额
   */
  spotTradeAmount: string
  /**
   * 新手现货交易奖励比例
   */
  spotTradeRewardRate: string
  /**
   * 当前策略累计收益率
   */
  tacticsCurrentProfitRate: string
  /**
   * 策略收益率
   */
  tacticsProfitRate: string
  /**
   * 策略奖励
   */
  tacticsReward: string
  /**
   * 限时任务交易额
   */
  timeTransactionAmount: string
  /**
   * 当前限时任务交易额
   */
  timeTransactionCurrentAmount: string
  /**
   * 当前限时任务充值量
   */
  timeTransactionCurrentDepositAmount: string
  /**
   * 限时任务充值量
   */
  timeTransactionDepositAmount: string
  /**
   * 限时任务奖励
   */
  timeTransactionReward: string
}

export interface GuideArticleItem {
  /**
   * 文章多语言公共id
   */
  articleBaseId: string
  /**
   * 文章id
   */
  articleId: number
  /**
   * 封面图url
   */
  imagePath: string
  /**
   * 标题
   */
  title: string
  /** 发布时间 */
  releaseTime: string
}

export interface GuideCategoryItem {
  /**
   * 类别多语言公共id
   */
  categoryId: string
  /**
   * 类别描述
   */
  description: string
  /**
   * 类别id
   */
  id: number
  language: string
  /**
   * 类别名称
   */
  name: string
  categoryName: string
}

export interface GuideArticleDetail {
  /**
   * 文章id
   */
  articleId: number
  /**
   * 类别id
   */
  categoryId: string
  /**
   * 类别名称
   */
  categoryName: string
  /**
   * 描述
   */
  description: string
  /**
   * 图片路径
   */
  imagePath: string
  /**
   * 语言
   */
  language: string
  /**
   * 客户端展示时间
   */
  releaseTime: string
  /**
   * 标签列表
   */
  tagList: string[]
  /**
   * 标题
   */
  title: string
}
export interface ChristmasInviteUserInfo {
  /**
   * 账户
   */
  account: string
  /**
   * 是否完成首次交易
   */
  firstTrade: boolean
  /**
   * 邀请时间
   */
  inviteTime: string
  /**
   * kyc等级
   */
  kycLevel: CustomInviteKycLevel
}
export interface ChristmasLottery {
  /**
   * 奖品id
   */
  id: string
  /**
   * 奖品类型，奖励类型, 1体验金 2抵扣金 3 真U 4 IDO白名单 5真U()
   */
  recordType: ChristmasRecordType
  /**
   * 奖品TOKEN_ID
   */
  tokenId: string
  /**
   * 中奖类型，1银2金
   */
  type: ChristmasLotteryType
  /**
   * 奖品数值，IDO没有数值
   */
  value: string
}

export interface ChristmasUserInfo {
  /**
   * 合约交易额
   */
  futuresTradeUsdt: string
  /**
   * 金次数
   */
  goldCount: string
  /**
   * 银次数
   */
  silverCount: string
  /**
   * 现货交易额
   */
  spotTradeUsdt: string
  /**
   * 中奖列表
   */
  userRewardList: UserRewardList[]
}

export interface UserRewardList {
  /**
   * 创建时间
   */
  created: string
  /**
   * id
   */
  id: string
  /**
   * 奖励类型，奖励类型, 1体验金 2抵扣金 3 真U  4 IDO白名单 5真U()
   */
  recordType: ChristmasRecordType
  /**
   * 中奖币种ID
   */
  tokenId: string
  /**
   * 抽奖类型，1银2金
   */
  type: ChristmasLotteryType
  /**
   * 中奖值
   */
  value: string
}

export interface UserAssetInfo {
  coinAsset: string
  optionAsset: string
  optionCoinAsset: string
  financeAsset: string
  futuresCoinAsset: string
  totalAsset: string
  unit: string
  activeCoinAsset: string
  stakingAsset: string
  unrealisedPnlTotal: string
  followOrderAsset: string
  tacticsAsset: string
}

export interface HotSymbolInfo {
  /**
   * 热门合约
   */
  hotFutures: string[]
  /**
   * 热门币币
   */
  hotSymbols: string[]
  /**
   * 热门币种
   */
  hotTokens: string[]
}

export interface KycForm {
  /**
   * 国家代码
   */
  countryCode: string
  /**
   * 身份类型
   */
  cardType: string | number
  /**
   * 国家代码
   */
  firstName?: string
  /**
   * 国家代码
   */
  lastName?: string
  /**
   * 国家代码
   */
  name?: string
  /**
   * 身份信息
   */
  cardNo: string
  /**
   * 生日
   */
  birthday: string
  /**
   * 正面照
   */
  pic1?: string
  /**
   * 背面照
   */
  pic2?: string
  /**
   * 手持照
   */
  pic3?: string
}

/**
 * 提币表单类型
 */
export interface WithdrawForm {
  tokenId: string
  chainType: string
  search: string
  address: string
  /**
   * 输入的地址
   */
  addressInput: string
  address_ext: string
  quantity: string
  remarks: string
  order_id: string
  client_order_id: string
  /**
   * 是否是内部地址
   */
  isInnerAddress: boolean
  /**
   * 地址id
   */
  addressId: string
  notag: boolean
  innerType: InnerWithdrawType
  email: string
  uid: string
  mobile: string
  national_code: string
}

/**
 * 提币创建订单返回的订单信息
 */
export interface WithdrawOrderCreateOrderInfo {
  accountId: string
  address: string
  addressExt: string
  arriveQuantity: string
  canBeCancelled: boolean
  confirmNum: string | number
  failedReason: string | number
  failedReasonDesc: string
  fee: string
  feeTokenId: string
  feeTokenName: string
  isInternalTransfer: boolean
  kernelId: string
  orderId: string
  quantity: string
  remarks: string
  requiredConfirmNum: string | number
  status: string | number
  statusCode: string | number
  statusDesc: string | number
  time: string | number
  tokenId: string
  tokenName: string
  txid: string
  txidUrl: string
  userAddress: string
  userName: string
  walletHandleTime: string
}

/**
 * 跟单人配置
 */
export interface FollowConfig {
  userId: string
  accountId: string
  takeUserId: string
  nickName: string
  photoUrl: string
  symbolIdList: string[]
  takeSymbolIdList: string[]
  followType: number
  followScale: string
  followScaleType: FollowScaleTypes
  followAmount: string
  followLeverType: number
  longLeverage: string
  shortLeverage: string
  profitRate: string
  lossRate: string
  drawScale: string
}

/**
 * 仓位历史记录
 */
export interface PositionHistory {
  /**
   * 平仓均价
   */
  closePositionPrice: string
  /**
   * 已平仓量
   */
  closePositionQuantity: string
  /**
   * 是否全仓，true:全仓，false：逐仓
   */
  isCross: boolean
  /**
   * 是不是多仓 1:多仓 0:空仓
   */
  isLong: number
  /**
   * 币种图标
   */
  logoUrl: string
  /**
   * 最大持仓量
   */
  maxOpenPositionQuantity: string
  /**
   * 开仓价格
   */
  openPositionPrice: string
  /**
   * 默认 1:全部平仓
   */
  orderType: number
  /**
   * 最后平仓时间
   */
  positionCloseEndTime: string
  /**
   * 开仓时间
   */
  positionOpenStartTime: string
  /**
   * 平仓盈亏
   */
  realisedPnl: string
  /**
   * 币种
   */
  symbolId: string
}

/***
 * 币种充币的相关信息
*/
export interface DepositInfo {
  /**
   * 充币地址
   */
  address: string
  tokenType: string
  addressExt: string
  /**
   * 是否允许充币
   */
  allowDeposit: boolean
  /**
   * 可提现确认数
   */
  canWithdrawConfirmNum: number
  /**
   * 合约地址
   */
  contractAddress?: string
  /**
   * 区块浏览器
   */
  exploreUrl?: string
  isEOS: boolean
  /**
   * 最小充币数
   */
  minQuantity: string | number
  needAddressTag: boolean
  /**
   * 二维码
   */
  qrcode: string
  /**
   * 充值区块确认数
   */
  requiredConfirmNum: number
}

export interface UserActionLog {
  /**
   * 操作类型，参考管理后台用户管理-其他信息-最近操作记录
   */
  actionType: UserActionTypes
  /**
   * 时间
   */
  created: string
  id: string
  /**
   * ip
   */
  ip: string
  /**
   * 操作平台，MOBILE PC
   */
  platform: 'MOBILE' | 'PC'
  /**
   * 地区
   */
  region: string
  /**
   * 状态，0成功 非0失败
   */
  resultCode: number
  /**
   * 系统类型
   */
  system: string
}

export interface CustkycSettings {
  level2: Level2
  level3Description: string
}

export interface Level2 {
  frontTitle: string
  backTitle: string
  holdDescription: string
  backBackgroundWeb: string
  backDescription: string
  frontBackgroundWeb: string
  extDescription: string
  holdBackgroundWeb: string
  backBackground: string
  holdBackground: string
  frontBackground: string
  frontDescription: string
  holdTitle: string
}

export interface KycVerifyInfo {
  nationality: string
  countryCode: string
  firstName: string
  secondName: string
  gender: number
  cardType: string
  cardNo: string
  cardFrontUrl: string
  cardBackUrl: string
  cardHandUrl: string
  verifyStatus: KycAuthStatusType
  refusedReasonId: number
  refusedReason: string
  kycLevel: number
  displayLevel: string
  birthday: string
  unLevelLimit: string
  levelOneLimit: string
  levelTwoLimit: string
}

export interface GaInfo {
  secretKey: string
  authUrl: string
  qrcode: string
}

export interface SendVerifyCodeResponse {
  orderId: string
  index: string
}
/**
 * 极验信息
 */
export interface GeetestData extends GeetestResult {
  gee_version: string
}

/**
 * 跟单列表、跟单人列表
 */
export interface OrderFollowListItem {
  cumulativeProfit: string
  followAmount: string
  followCount: number
  followCountLimit: number
  followLeverType: number
  followScale: string
  followScaleType: number
  longLeverage: string
  lossRate: string
  name: string
  profit: string
  profitRate: string
  shortLeverage: string
  symbolIdList: string[]
  takeName: string
  takePhoto: string
  takeUserId: string
  userId: string
}

/**
 * 邀请信息轮播图
 */
export interface InvationAwardCarouselItem {
  awardAmount: string
  invitedUserId: string
}

/**
 * 邀请分享信息
 */
export interface InvitationShareInfo {
  brokerLogoUrl: string
  brokerName: string
  hide: number
  inviteCode: string
  posterUrl: string
  posterUrls: string[]
  shareContent: string
  shareTitle: string
  shareUrl: string
}

/**
 * 未登录发送手机验证码
 */
export interface SendCommonSmsVerifyCode extends GeetestData {
  national_code: string
  mobile: string
  type: CodeType
}

export interface GetOAuthCodeResult {
  oauthCode: string
  expired: number
  state: string
  redirectUrl: string
}

/***
 * 登录第一步返回信息
*/
export interface UserAuthorize {
  authType: string
  bindEmail: boolean
  bindGA: boolean
  bindMobile: boolean
  defaultAccountId: string
  email: string
  mobile: string
  nationalCode: string
  need2FA?: boolean
  registerType: RegisterType
  requestId?: string
  timeLeft: string
  userId: string
  token?: string
  needCheckPassword?: boolean
  user?: any
  oauthCodeResult?: GetOAuthCodeResult
}

export interface IEOProjectInfo {
  /**
   * 结束时间戳
   */
  endTime?: string
  /**
   * id
   */
  id?: string
  /**
   * 项目简介
   */
  introducation?: string
  /**
   * 个人代币硬顶，新增
   */
  maxOfferingsLimit: string
  /**
   * 最小投入量，新增
   */
  minPurchaseLimit: string
  /**
   * 发行币种名称
   */
  offeringsToken?: string
  /**
   * 发行币种总量
   */
  offeringsVolume?: string
  /**
   * 项目编码
   */
  projectCode?: string
  /**
   * 项目名称
   */
  projectName?: string
  /**
   * 价格，新增 1ABC=xxUSDT
   */
  purchasePrice: string
  /**
   * 支付币种，新增
   */
  purchaseToken: string
  /**
   * 结束倒计时
   */
  remainMillSecondForEnd?: string
  /**
   * 开始倒计时
   */
  remainMillSecondForStart?: string
  /**
   * 开始时间戳，新增
   */
  startTime: string
  /**
   * 状态，1=预热,2=开始购买,3=购买结束,4=公布结果(在阳光普照类型下就是结束状态),5=结束
   */
  status?: IEOStatus
  activityType?: IEOActivityType
  /**
   * 项目图片
   */
  url?: string
  /** 币种logo */
  logo?: string
}

export interface IEOBasicInfo {
  /**
   * 活动类型，2阳光普照 3抢购模式
   */
  activityType?: IEOActivityType
  /**
   * 背景图片APP
   */
  appBackUrl: string
  /**
   * 可申购份数
   */
  availablePurchaseUnit: string
  /**
   * 得到
   */
  backAmount: string
  balanceRuleJson?: string
  /**
   * 项目图片
   */
  bannerUrl?: string
  brokerId?: string
  /**
   * 区块链浏览器
   */
  browser?: string
  /**
   * 自定义总发行量
   */
  circulationStr?: string
  createdTime?: string
  deadlineTime?: string
  /**
   * 项目描述
   */
  description?: string
  /**
   * 详细规则
   */
  detailRule: string
  discrod: string
  /**
   * 官网
   */
  domain?: string
  /**
   * 结束时间
   */
  endTime?: string
  facebook: string
  /**
   * 项目简介
   */
  introduction?: string
  /**
   * 是否限额项目，1
   */
  isPurchaseLimit?: number
  levelLimit?: string
  /**
   * logo
   */
  logo: string
  /**
   * 返还
   */
  luckyAmount: string
  /**
   * 个人代币硬顶
   */
  maxOfferingsLimit: string
  midume: string
  /**
   * 每份数量，最小投入量
   */
  minPurchaseLimit?: string
  /**
   * 平台总限额
   */
  platformLimit?: string
  /**
   * 状态，1=预热中,2=开始购买进行中(申购阶段),3=购买结束统计中(统计阶段),4=公布结果已结束(分发阶段)(阳光普照模式下就是最终结束状态),5=结束(抢购活动的结束状态)
   */
  progressStatus?: IEOStatus
  /**
   * 项目编码
   */
  projectCode?: string
  /**
   * id
   */
  projectId?: string
  /**
   * 项目名称
   */
  projectName?: string
  /**
   * 项目类型，0无固定锁仓周期 1 固定周期锁仓
   */
  projectType?: IEOProjectType
  /**
   * 平台剩余可购买数量
   */
  purchaseableQuantity?: string
  /**
   * 支付币种
   */
  purchaseTokenId?: string
  /**
   * 支付币种
   */
  purchaseTokenName?: string
  /**
   * 出售币种数量，1
   */
  receiveQuantity?: string
  /**
   * 发售币种
   */
  receiveTokenId?: string
  /**
   * 发售币种
   */
  receiveTokenName?: string
  /**
   * 结果公布时间
   */
  resultTime?: string
  /**
   * 出售总额，申购总额
   */
  soldAmount?: string
  /**
   * 开始时间
   */
  startTime?: string
  startTimeCountdown?: string
  /**
   * 状态，0删除 1开启 2关闭 3提前结束
   */
  status?: IEOBasicInfoStatus
  telegram: string
  /**
   * 项目名称
   */
  title?: string
  /**
   * 支付币种总发行量
   */
  totalCirculation: string
  /**
   * 名义发行总量，发行数量
   */
  totalReceiveCirculation: string
  twitter: string
  updatedTime?: string
  /**
   * 消耗
   */
  useAmount: string
  /**
   * 参与人数
   */
  userCount: string
  userLimit?: string
  /**
   * 我的申购
   */
  userPurchaseAmount: string
  /**
   * 发售价格，1ABC=xxUSDT
   */
  valuationQuantity: string
  verifyAvgBalance?: number
  verifyBalance?: number
  verifyBindPhone?: number
  verifyKyc?: number
  /**
   * 背景图片WEB
   */
  webBackUrl: string
  /**
   * 白皮书
   */
  whitePaper?: string
}

export interface IEOOrder {
  /**
   * 申购金额
   */
  amount?: string
  /**
   * 代币数量
   */
  luckyAmount: string
  /**
   * 订单号
   */
  orderId: string
  /**
   * 项目名称
   */
  projectName?: string
  /**
   * 发售价格
   */
  purchasePrice: string
  /**
   * 时间戳
   */
  purchaseTime: string
  /**
   * 发售币种
   */
  receiveTokenId: string
  /**
   * 订单状态，0进行中 1已完成
   */
  status: IEOOrderStatus
  /**
   * 支付币种
   */
  tokenId?: string
  /**
   * 成交金额
   */
  useAmount: string
}
